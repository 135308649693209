import { useEffect } from 'react'
import { Button, Modal, notification, Spin } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import styled from '@emotion/styled'
import { Camera } from '@capacitor/camera'
import {
  CameraDirection,
  CameraResultType,
  CameraSource,
} from '@capacitor/camera/dist/esm/definitions'
import Tooltip from 'antd/es/tooltip'
import { ImgHTMLAttributes } from 'react-router/node_modules/@types/react'
import { Show } from 'common/Show'
import { TImage } from 'common/Models'
import { ListaPedidosStore } from './ListaPedidosStore'
import { DownloadOutlined, RetweetOutlined } from '@ant-design/icons'
import { AuthStore, getUsuarioLogado } from '../../auth/AuthStore'
import useAxios from 'axios-hooks'

export const ImagensInstalacao = (props: ModalProps) => {
  const { Gallery, takePhoto, resizeBase64Image } = ImagensInstalacao

  const state = ListaPedidosStore.useState()
  const loading = state?.loadingImagensInstalacao
  const emplacamento = state?.pedidoAtivo?.emplacamento

  const usuario = AuthStore.useSelector(getUsuarioLogado)

  const equalToModalVisible = (modal) => modal === state.modalVisible

  const visible =
    (equalToModalVisible('IMAGENS_INSTALACAO') ||
      equalToModalVisible('ENCERRAR_ESTAMPAGEM_IMAGENS_INSTALACAO')) &&
    !!emplacamento?.codigoAutorizacaoDenatran

  const dispatch = ListaPedidosStore.useDispatch() as any

  useEffect(() => {
    if (!emplacamento?.codigoAutorizacaoDenatran || !visible) return

    dispatch(ListaPedidosStore.thunks.getImagensInstalacao(emplacamento?.codigoAutorizacaoDenatran))
  }, [emplacamento?.codigoAutorizacaoDenatran, visible])

  const [{ loading: loadingReenviar }, req] = useAxios(
    {
      url: '/emplacamentos/reenvia-imagens-detran-go',
      method: 'post',
      data: { pedidoId: state.pedidoAtivo?.id },
    },
    { manual: true }
  )

  const handleTakePhoto = async () => {
    const { cancelled, success, message, image } = await takePhoto()

    if (cancelled) return
    if (!success) return notification.error({ message })

    const resizedImage = await resizeBase64Image(image)

    try {
      const localization: any = await new Promise((resolve, reject) => {
        if (usuario?.admin && emplacamento.coordenadasImagensInstalacao) {
          try {
            const { latitude, longitude } = JSON.parse(emplacamento.coordenadasImagensInstalacao)
            return resolve({ coords: { latitude, longitude } })
          } catch (error) {}
        }
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })

      const imageToUpload = {
        imageBase64: resizedImage,
        localization: {
          latitude: localization.coords.latitude,
          longitude: localization.coords.longitude,
        },
      } as TImage

      dispatch(ListaPedidosStore.thunks.uploadImagemInstalacao(imageToUpload))
    } catch (e: any) {
      if (e.message === 'User denied geolocation prompt') {
        return notification.error({ message: 'Ative a localização do seu dispositivo!' })
      }

      notification.error({ message: 'Erro ao obter localização!' })
    }
  }

  async function handleDownload() {
    window.open(
      `${process.env.REACT_APP_API_HOST}/emplacamentos/imagens-instalacao/${state.pedidoAtivo?.emplacamento?.codigoAutorizacaoDenatran}/download`
    )
  }

  const handleDeleteImage = async (image: TImage) => {
    if (usuario?.admin) {
      if (!confirm('Tem certeza que deseja deletar essa imagem?')) return
    }
    dispatch(ListaPedidosStore.thunks.deleteImagemInstalacao(image))
  }

  const handleCloseModal = () => {
    if (state.modalVisible === 'ENCERRAR_ESTAMPAGEM_IMAGENS_INSTALACAO') {
      dispatch(ListaPedidosStore.thunks.encerrarEstampagem(state.pedidoAtivo))
      return
    }

    dispatch(ListaPedidosStore.actions.closeModal())
  }

  const handleReenviaImagensDetran = async () => {
    try {
      const responseReenvio = await req().then((x) => x.data)
      if (responseReenvio.status === 'INFORMADO_DETRAN') {
        return notification.success({ message: 'Reenviado ao detran com sucesso' })
      }
      return notification.error({ message: 'Ocorreu um erro ao enviar as imagens ao detran' })
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao reenviar as imagens'
      notification.error(message)
    }
  }

  return (
    <Modal
      title="Imagens da Instalação"
      footer={null}
      width="100"
      visible={visible}
      onCancel={handleCloseModal}
      {...props}
    >
      <div style={{ textAlign: 'center' }}>
        <Spin size="large" spinning={loading} />
      </div>

      <Show condition={!loading}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Show condition={usuario?.admin && emplacamento?.dataEnvioImagensInstalacaoDetranGo}>
            <Tooltip title="Reenvia imagens ao detran">
              <Button
                size="large"
                type="primary"
                icon={<RetweetOutlined color="#ffffff" />}
                onClick={handleReenviaImagensDetran}
                style={{ margin: '5px' }}
                shape="circle"
                danger
                loading={loadingReenviar}
              ></Button>
            </Tooltip>
          </Show>
          <Show condition={!emplacamento?.dataEnvioImagensInstalacaoDetranGo || usuario?.admin}>
            <Tooltip title="Tirar Foto">
              <Button
                size="large"
                type="primary"
                icon={<CameraIcon fill="#ffffff" />}
                onClick={handleTakePhoto}
                style={{ margin: '5px' }}
                shape="circle"
              ></Button>
            </Tooltip>
          </Show>
          <Tooltip title="Efetuar download do PDF">
            <Button
              size="large"
              type="primary"
              icon={<DownloadOutlined style={{ color: '#ffffff' }} />}
              onClick={handleDownload}
              style={{ margin: '5px' }}
              shape="circle"
            />
          </Tooltip>
        </div>

        <Gallery>
          {emplacamento?.imagensInstalacao?.map((image, index) => (
            <Image key={index} image={image} onDelete={handleDeleteImage} />
          ))}
        </Gallery>
      </Show>
    </Modal>
  )
}
ImagensInstalacao.Gallery = styled.ul`
  margin: 10px 0px;
  padding: 0px;
  text-align: center;
`
ImagensInstalacao.takePhoto = async () => {
  try {
    const photo = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      direction: CameraDirection.Front,
    })

    return {
      success: true,
      image: photo?.base64String ?? '',
    }
  } catch (e: any) {
    if (e.message === 'User cancelled photos app') return { cancelled: true }

    return {
      success: false,
      message: e === '' ? 'Nenhuma camera encontrada!' : 'Ocorreu um erro ao capturar a foto!',
    }
  }
}
ImagensInstalacao.fileToBase64 = async (file) => {
  return await new Promise<string>((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      resolve(reader.result as string)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  })
}
ImagensInstalacao.resizeBase64Image = async (image) => {
  return await new Promise<string>((resolve) => {
    const img = document.createElement('img')

    img.onload = function () {
      const canvas = document.createElement('canvas')
      const ctx: any = canvas.getContext('2d')
      ctx.imageSmoothingEnabled = true
      ctx.imageSmoothingQuality = 'high'

      const ratio = 640 / Math.max(img.width, img.height)

      canvas.width = img.width * ratio
      canvas.height = img.height * ratio

      ctx.drawImage(this, 0, 0, canvas.width, canvas.height)

      const dataURL = canvas.toDataURL('image/jpeg', 0.7)

      resolve(dataURL.replace(/^data:([A-Za-z-+/]+);base64,/, 'data:image;base64,'))
    }

    img.src = 'data:image;base64,' + image.replace(/^data:([A-Za-z-+/]+);base64,/, '')
  })
}

const Image = ({
  image,
  onDelete,
  ...props
}: {
  image: TImage
  onDelete?: (image: TImage) => any
} & ImgHTMLAttributes<any>) => {
  const { Layout } = Image

  const state = ListaPedidosStore.useState()
  const emplacamento = state?.pedidoAtivo?.emplacamento
  const usuario = AuthStore.useSelector(getUsuarioLogado)

  return (
    <Layout {...props}>
      <Show condition={!emplacamento?.dataEnvioImagensInstalacaoDetranGo || usuario?.admin}>
        <Tooltip title="Excluir Foto">
          <Button
            size="small"
            type="primary"
            className="btn-delete"
            onClick={() => onDelete?.(image)}
          >
            X
          </Button>
        </Tooltip>
      </Show>

      <Spin size="large" className="spin" spinning={false} />

      <div className="image-container">
        <img style={{ width: '100%' }} src={image.imageBase64} />
      </div>
    </Layout>
  )
}
Image.Layout = styled.div`
  display: inline-block;
  width: 270px;
  overflow: hidden;
  border: 1px solid #aaa;
  position: relative;
  margin: 10px;

  :hover {
    border: 2px solid #519fb3;
    box-shadow: 1px 1px 20px 4px #b3b3b3;
  }

  .btn-delete {
    position: absolute;
    right: 0%;
    margin: 4px 4px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 800;
    /* width: 10px; */
    background-color: transparent;
    border-color: transparent;
    background-color: #ff0000e1;
    border-color: #ff0000c0;
  }

  .spin {
    position: absolute;
    top: 45%;
    left: 45%;
  }

  .image-container {
    height: 100%;
    display: grid;
    align-items: center;
  }
`

// Icons

const CameraIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M5 4h-3v-1h3v1zm8 6c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm11-5v17h-24v-17h5.93c.669 0 1.293-.334 1.664-.891l1.406-2.109h8l1.406 2.109c.371.557.995.891 1.664.891h3.93zm-19 4c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm13 4c0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5 5-2.239 5-5z" />
    </svg>
  )
}
