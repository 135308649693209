import React from 'react'
import { Show } from '../../common/Show'
import { Button, Modal } from 'antd'
import styled from '@emotion/styled'
import { STATUS_TEXTOS } from './ListaPedidos'
import { DisplayFlex } from '../../common/DisplayFlex'
import { ListaPedidosStore } from './ListaPedidosStore'
import { AuthStore, getEmpresaAtiva, getUsuarioLogado } from '../../auth/AuthStore'
import { format, parseISO } from 'date-fns'
import { PERMISSOES, ProdutoModel } from 'common/Models'
import { RestrictedResource } from 'common/RestrictedResource'
import { StatusEmplacamentoDetran } from '../../common/seplac/StatusEmplacamentoDetran'
import { ImageIcon } from '../../assets/image-icon'

export function OrdemServico() {
  const { modalVisible } = ListaPedidosStore.useState()
  const dispatch = ListaPedidosStore.useDispatch()

  function handleCloseModal() {
    dispatch({ type: 'closeModal' })
  }

  function handleClickImprimir() {
    const root = document.getElementById('root')
    if (!root) return
    root.style.height = '842px'
    root.style.overflow = 'hidden'
    requestAnimationFrame(() => {
      window.print()
      requestAnimationFrame(() => {
        root.style.height = 'auto'
        root.style.overflow = 'unset'
      })
    })
  }

  return (
    <Modal
      visible={modalVisible === 'ORDEM_SERVICO'}
      style={{ top: 20 }}
      onCancel={handleCloseModal}
      width={645}
      footer={[
        <Button key="close" onClick={handleCloseModal}>
          Fechar
        </Button>,
        <Button key="ok" type="primary" onClick={handleClickImprimir}>
          Imprimir
        </Button>,
      ]}
    >
      <>
        <Status />
        <ContentModal />
      </>
    </Modal>
  )
}

function Status() {
  const { Titulo, Layout } = Status
  const { pedidoAtivo } = ListaPedidosStore.useState()

  return (
    <div>
      <Titulo>{STATUS_TEXTOS[pedidoAtivo.emplacamento?.status]}</Titulo>
      <div style={{ marginTop: 12 }}>
        <Layout>
          <BotaoStatus />
        </Layout>
      </div>
    </div>
  )
}
Status.Titulo = styled.div`
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin-top: 12px;
`
Status.Layout = styled.div`
  display: flex;
  justify-content: space-around;

  button {
    margin: 0px 10px;
  }

  @media (max-width: 600px) {
    display: grid;

    button {
      width: 100%;
      margin: 5px;
    }
  }
`

function BotaoStatus() {
  const { pedidoAtivo } = ListaPedidosStore.useState()
  const dispatch = ListaPedidosStore.useDispatch()
  const admin = AuthStore.useSelector(getUsuarioLogado)?.admin
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)

  if (pedidoAtivo.emplacamento.status === 'AGUARDANDO_ESTAMPAGEM') {
    return (
      <>
        <Show condition={admin}>
          <Button
            size="large"
            type="primary"
            onClick={() => dispatch(ListaPedidosStore.actions.visualizarHistorico(pedidoAtivo))}
          >
            Visualizar Histórico
          </Button>
        </Show>
        <Button
          size="large"
          type="primary"
          onClick={() => dispatch(ListaPedidosStore.thunks.iniciarEstampagem(pedidoAtivo))}
          style={{ width: 150 }}
        >
          Estampar
        </Button>
        {/* {botaoCorrigir} */}
      </>
    )
  }

  if (pedidoAtivo.emplacamento.status === 'AGUARDANDO_PAGAMENTO') {
    return (
      <>
        <Show condition={admin}>
          <Button
            size="large"
            type="primary"
            onClick={() => dispatch(ListaPedidosStore.actions.visualizarHistorico(pedidoAtivo))}
          >
            Visualizar Histórico
          </Button>
        </Show>
        <Button
          size="large"
          type="primary"
          onClick={() => dispatch(ListaPedidosStore.thunks.enviarEstampagem(pedidoAtivo))}
        >
          Enviar para Estampagem
        </Button>
      </>
    )
  }

  if (pedidoAtivo.emplacamento.status === 'ESTAMPADA') {
    return (
      <>
        <Show condition={admin}>
          <Button
            size="large"
            type="primary"
            onClick={() => dispatch(ListaPedidosStore.actions.visualizarHistorico(pedidoAtivo))}
          >
            Visualizar Histórico
          </Button>
        </Show>
        <Button
          size="large"
          type="primary"
          style={{ height: '40px', width: '100%' }}
          onClick={() => dispatch(ListaPedidosStore.thunks.encerrarEstampagem(pedidoAtivo))}
        >
          Instalar
        </Button>
        <ButtonImagensInstalacao />
      </>
    )
  }

  if (pedidoAtivo.emplacamento.status === 'INSTALADA') {
    return (
      <>
        <Show condition={admin}>
          <Button
            size="large"
            type="primary"
            onClick={() => dispatch(ListaPedidosStore.actions.visualizarHistorico(pedidoAtivo))}
          >
            Visualizar Histórico
          </Button>
        </Show>
        <Show condition={!pedidoAtivo.emplacamento.numeroNF || empresaAtiva?.emiteNotaFiscal}>
          <Button
            size="large"
            type="primary"
            onClick={() => dispatch(ListaPedidosStore.actions.openModalNotaFiscal(pedidoAtivo))}
          >
            {!pedidoAtivo.emplacamento.numeroNF ? 'Informar Nota Fiscal' : 'Nota Fiscal'}
          </Button>
        </Show>
        <ButtonImagensInstalacao />
      </>
    )
  }

  return null
}

const ButtonImagensInstalacao = () => {
  const { pedidoAtivo } = ListaPedidosStore.useState()
  const dispatch = ListaPedidosStore.useDispatch()

  return (
    <Show condition={['ESTAMPADA', 'INSTALADA'].includes(pedidoAtivo.emplacamento.status)}>
      <Button
        style={{ height: '40px' }}
        type="primary"
        onClick={() => dispatch(ListaPedidosStore.actions.openModalImagensInstalacao(pedidoAtivo))}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '5px',
            fontSize: '16px',
          }}
        >
          <ImageIcon fill="#dfdfdf" /> Imagens da Instalação
        </div>
      </Button>
    </Show>
  )
}

function ContentModal() {
  const { pedidoAtivo } = ListaPedidosStore.useState()
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)

  if (!pedidoAtivo?.id) return null
  const isUsuarioHipodromo = false

  return (
    <Styles.OrdemServico>
      <Show condition={isUsuarioHipodromo}>
        <div className="titulo">Emplacador</div>
        <div>{pedidoAtivo.empresaOrigem.descricao}</div>
      </Show>

      <div className="titulo"> Dados do Veículo </div>

      <DisplayFlex even={true}>
        <div>
          <div className="label"> Placa </div>
          {pedidoAtivo.emplacamento.placa}
        </div>
        <div>
          <div className="label"> Serviço </div>
          {pedidoAtivo.servico.descricao}
        </div>
      </DisplayFlex>

      <DisplayFlex even={true}>
        <div>
          <div className="label"> Categoria </div>
          {pedidoAtivo.emplacamento.categoriaVeiculo}
        </div>
        <div>
          <div className="label"> Espécie </div>
          {pedidoAtivo.emplacamento.especieVeiculo}
        </div>
      </DisplayFlex>

      <DisplayFlex even={true}>
        <div>
          <div className="label"> Marca/Modelo </div>
          {pedidoAtivo.emplacamento.marcaModelo}
        </div>
        <div>
          <div className="label"> Chassi </div>
          {pedidoAtivo.emplacamento.chassi}
        </div>
      </DisplayFlex>

      <DisplayFlex even={true}>
        <div>
          <div className="label"> Código de Autorização do Denatran </div>
          {pedidoAtivo.emplacamento.codigoAutorizacaoDenatran || '-'}
        </div>
        <Show condition={empresaAtiva?.municipio.uf === 'GO'}>
          <div>
            <div className="label"> Código de Autorização do Detran </div>
            {pedidoAtivo.emplacamento.codigoAutorizacaoDetran || '-'}
          </div>
        </Show>
      </DisplayFlex>
      <div>
        <div className="label"> Município </div>
        {pedidoAtivo.emplacamento.municipio?.nome}
      </div>
      <Show condition={pedidoAtivo.emplacamento.numeroNF}>
        <DisplayFlex>
          <div>
            <div className="label">Nota Fiscal</div>
            {pedidoAtivo.emplacamento.numeroNF}
          </div>
        </DisplayFlex>
      </Show>

      <div className="titulo">Proprietário</div>

      <DisplayFlex even={true}>
        <div>
          <div className="label">
            {pedidoAtivo.emplacamento.cliente.cpfCnpj.length === 14 ? 'Nome' : 'Razão Social'}
          </div>
          {pedidoAtivo.emplacamento.cliente.nome}
        </div>
        <div>
          <div className="label">
            {pedidoAtivo.emplacamento.cliente.cpfCnpj.length === 14 ? 'CPF' : 'CNPJ'}
          </div>
          {pedidoAtivo.emplacamento.cliente.cpfCnpj}
        </div>
      </DisplayFlex>

      <DisplayFlex even={true}>
        <div>
          <div className="label">E-mail</div>
          {pedidoAtivo.emplacamento.cliente.email}
        </div>
        <div>
          <div className="label">Telefone</div>
          {pedidoAtivo.emplacamento.cliente.telefone}
        </div>
      </DisplayFlex>

      <Show condition={pedidoAtivo.emplacamento.codDespachante}>
        <div>
          <div className="label"> Despachante </div>
          {pedidoAtivo.emplacamento.codDespachante} - {pedidoAtivo.emplacamento.nomeDespachante}
        </div>
      </Show>

      <div>
        <div>
          <div className="label">Data de criação do pedido</div>
          {format(parseISO(pedidoAtivo.emplacamento.createdAt), 'dd/MM/yyyy HH:mm')}
        </div>
        {/* <div>
            <div className="label">Pagamento</div>
            {pedidoAtivo.emplacamento.dataPagamento ? `Confirmado ${format(parseISO(pedidoAtivo.emplacamento.dataPagamento), 'dd/MM/yyyy')}` : 'Pendente'}
            <CheckOutlined style={{ marginLeft: '5px', color: 'green' }} />
          </div> */}
      </div>

      <Show condition={pedidoAtivo.produtos.length}>
        <div className="titulo">Blanks</div>
        {pedidoAtivo.produtos.map((produto) => (
          <Produto key={produto.id} produto={produto} />
        ))}
      </Show>

      <Show condition={pedidoAtivo.emplacamento.status === 'INSTALADA'}>
        <div className="no-print">
          <br />
          <StatusEmplacamentoDetran pedido={pedidoAtivo} />
        </div>
      </Show>
    </Styles.OrdemServico>
  )
}

const Produto = ({ produto }: { produto: ProdutoModel }) => {
  const { pedidoAtivo } = ListaPedidosStore.useState()
  const dispatch = ListaPedidosStore.useDispatch()

  function handleOpenModalSubstituicao(produto) {
    dispatch(ListaPedidosStore.actions.openModalSubstituicao(produto))
  }

  return (
    <DisplayFlex>
      <div>
        <div className="label">{produto.tipoProduto.descricao}</div>
        {produto.serial}
      </div>
      <Show condition={pedidoAtivo.emplacamento.status === 'ESTAMPADA'}>
        <RestrictedResource permissoes={[PERMISSOES.SUBSTITUIR_BLANKS]} renderWhenForbidden={null}>
          <Button type="primary" onClick={() => handleOpenModalSubstituicao(produto)}>
            Substituir
          </Button>
        </RestrictedResource>
      </Show>
    </DisplayFlex>
  )
}

const Styles = {
  StatusPedido: styled('div')`
    @media print {
      display: none;
    }
    .container {
      text-align: center;
    }
    .info-icon {
      font-size: 4em;
      margin: 12px;
    }
    .data {
      font-size: 0.8em;
      font-weight: normal;
      margin-top: 12px;
    }
  `,
  OrdemServico: styled('div')`
    @media print {
      position: fixed;
      background: white;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100vh;
      z-index: 100;
      & > div:last-child {
        padding-bottom: 24px;
        border-bottom: 1px dashed black;
      }
      .no-print {
        display: none;
      }
    }
    font-size: 1.4em;
    line-height: 1.2em;
    font-weight: bold;
    /* margin-right: 40px; */
    .titulo {
      font-size: 0.8em;
      color: #888;
      border-bottom: 1px solid #ddd;
      width: 100%;
      margin-top: 16px;
      margin-bottom: 8px;
    }
    .label {
      font-size: 0.9em;
      font-weight: normal;
      margin-top: 6px;
    }
  `,
}
