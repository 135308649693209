import { Button, Modal, Table, Tooltip } from 'antd'
import React from 'react'
import useAxios from 'axios-hooks'
import { ListaPedidosStore } from './ListaPedidosStore'
import { Show } from '../../common/Show'
import { InfoCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { format, parseISO } from 'date-fns'

export const Historico = () => {
  const dispatch = ListaPedidosStore.useDispatch()
  const { pedidoAtivo, modalVisible } = ListaPedidosStore.useState()
  const emplacamentoId = pedidoAtivo?.emplacamento?.id

  function closeModal() {
    dispatch({ type: 'closeModal' })
  }

  return (
    <HistoricoContainer
      emplacamentoId={modalVisible === 'VISUALIZAR_HISTORICO' ? emplacamentoId : null}
      closeModal={closeModal}
    />
  )
}

export const HistoricoContainer = ({
  emplacamentoId,
  closeModal,
}: {
  emplacamentoId
  closeModal
}) => {
  const { Layout } = HistoricoContainer

  const [{ data }] = useAxios(`/emplacamentos/historico/${emplacamentoId}`, {
    manual: !emplacamentoId,
  })

  return (
    <Modal
      visible={emplacamentoId}
      title="Histórico do pedido"
      onCancel={closeModal}
      width="90vw"
      footer={[
        <Button key="cancel" size="large" onClick={closeModal}>
          Fechar
        </Button>,
      ]}
    >
      <Layout>
        <Table
          dataSource={data}
          columns={[
            {
              title: 'Data',
              render: (row) => format(parseISO(row.createdAt), 'dd/MM/yyyy HH:mm'),
            },
            {
              title: 'Evento',
              dataIndex: 'tipo',
            },
            {
              title: 'Usuário',
              render: (row) => <>{row.usuario ? row.usuario.nome : 'OPERAÇÃO AUTOMÁTICA'}</>,
            },
            {
              title: 'Informações Adicionais',
              render: (row) => (
                <Show condition={!!row.informacoesAdicionais}>
                  <Tooltip
                    title={<pre>{JSON.stringify(row.informacoesAdicionais, null, 2)}</pre>}
                    placement="right"
                  >
                    <InfoCircleOutlined onClick={() => console.log(row.informacoesAdicionais)} />
                  </Tooltip>
                </Show>
              ),
            },
          ]}
        />
      </Layout>
    </Modal>
  )
}
HistoricoContainer.Layout = styled.div``
