import styled from '@emotion/styled'
import { notification, Modal, Button, Input } from 'antd'
import React, { useState, useRef, useEffect, ReactNode } from 'react'
import { AuthStore, getEmpresaAtiva } from '../../auth/AuthStore'
import { PedidoModel, ServicoModel, SERVICOS } from '../../common/Models'
import { ListaPedidosStore } from './ListaPedidosStore'
import IntlNumberInput from 'react-intl-number-input'
import useAxios from 'axios-hooks'
import { Show } from '../../common/Show'
import axios from 'axios'
import { createSimpleStore } from 'react-simple-reducer'

interface IServicoNovoPedido {
  id: number
  idFlow: string
  descricao: string
}

const Store = createSimpleStore(
  {
    empresaParticipanteAcordoMercadoPago: false,
    codigoAutorizacao: '',
    criaPedidoEmplacafacil: false,
    valorInformado: '',
    loading: { createEmplacamento: false, getServicos: false },
    servico: null as null | IServicoNovoPedido,
  },
  {
    clear(state) {
      state.codigoAutorizacao = ''
      state.valorInformado = ''
      state.servico = null
    },
    successGetValorFlow(state, responseFlow) {},
    changeCodigoAutorizacao(state, codigoAutorizacao) {
      state.codigoAutorizacao = codigoAutorizacao
      state.valorInformado = ''
      state.servico = null
    },
    createEmplacamentoStarted(state) {
      state.loading.createEmplacamento = true
    },
    createEmplacamentoSuccess(state) {
      state.loading.createEmplacamento = false
    },
    createEmplacamentoError(state) {
      state.loading.createEmplacamento = false
    },
    changeValorInformado(state, valorInformado) {
      state.valorInformado = valorInformado
    },
    changeCriaPedidoEmplacafacil(state, criaPedidoEmplacafacil) {
      state.criaPedidoEmplacafacil = criaPedidoEmplacafacil
    },

    getServicoStarted(state) {
      state.loading.getServicos = true
    },
    getServicoSuccess(state, servico: IServicoNovoPedido) {
      state.servico = servico
      state.loading.getServicos = false
    },
    getServicoError(state) {
      state.loading.getServicos = false
    },
  },
  {
    thunks: {
      getServico(codigoAutorizacao) {
        return async (dispatch) => {
          try {
            dispatch(Store.actions.getServicoStarted())
            const responseAutorizacao = await axios
              .get(`emplacamentos/autorizacao-estampagem/${codigoAutorizacao}`)
              .then((x) => x?.data)

            const servicoId = getServicoId(
              responseAutorizacao.autorizacaoEstampagem.veiculo.tipo,
              responseAutorizacao.autorizacaoEstampagem.tiposPlacas
            )
            const { descricao, idFlow } = {
              [SERVICOS.PLACA_DE_MOTO]: {
                descricao: 'Placa de Moto',
                idFlow: '12',
              },
              [SERVICOS.PAR_DE_PLACAS]: {
                descricao: 'Par de Placas',
                idFlow: '9',
              },
              [SERVICOS.PLACA_DIANTEIRA]: {
                descricao: 'Placa Dianteira',
                idFlow: '10',
              },
              [SERVICOS.PLACA_TRASEIRA]: {
                descricao: 'Placa Traseira',
                idFlow: '11',
              },
              [SERVICOS.SEGUNDA_PLACA_TRASEIRA]: {
                descricao: 'Segunda Placa Traseira',
                idFlow: '13',
              },
            }[servicoId]
            dispatch(
              Store.actions.getServicoSuccess({
                id: servicoId,
                descricao,
                idFlow,
              })
            )
          } catch (error: any) {
            const message =
              error.response?.data?.message ?? 'Ocorreu um erro inesperado ao buscar o serviço'
            notification.error({ message })
            dispatch(Store.actions.getServicoError())
          }
        }
      },
      createEmplacamento(listaPedidosDispatch, idEmpresaAtiva, ufEmpresaAtiva) {
        return async (dispatch, getState) => {
          const { codigoAutorizacao, valorInformado } = getState()
          const criaPedidoEmplacafacil = getState().criaPedidoEmplacafacil

          if (ufEmpresaAtiva === 'GO' && codigoAutorizacao.length < 24) {
            return notification.error({
              message: 'Informe o código de autorização para estampagem do DETRAN',
            })
          }
          if (
            ufEmpresaAtiva !== 'GO' &&
            !isPlaca(codigoAutorizacao) &&
            codigoAutorizacao.length !== 15
          ) {
            return notification.error({
              message: 'Informe o código de autorização do SENATRAN ou a Placa',
            })
          }

          try {
            dispatch(Store.actions.createEmplacamentoStarted())
            const { data } = await axios.post('/emplacamentos', {
              codigoAutorizacao,
              criaPedidoEmplacafacil,
              valorTotalEstampagem: unmaskMoney(valorInformado),
            })
            listaPedidosDispatch(ListaPedidosStore.thunks.getPedidos(idEmpresaAtiva))
            listaPedidosDispatch(ListaPedidosStore.actions.closeModal())
            dispatch(Store.actions.createEmplacamentoSuccess())

            if (criaPedidoEmplacafacil) {
              listaPedidosDispatch(ListaPedidosStore.actions.displayBoleto(data.boleto))
            } else {
              notification.success({ message: 'Pedido criado com sucesso' })
            }
          } catch (e: any) {
            const message = e.response?.data?.message ?? 'Ocorreu um erro inesperado'
            notification.error({ message })
            dispatch(Store.actions.createEmplacamentoError())
          }
        }
      },
    },
  }
)

export function NovoPedido() {
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)
  return (
    <Store.Provider>
      <ModalNovoPedido cancelar={<Cancelar />} ok={<Ok />}>
        <CodigoAutorizacao />
        <Show condition={empresaAtiva?.municipio?.uf === 'GO'}>
          <InfoPreenchimentoCodigoAutorizacaoObrigatorio />
        </Show>
        <Servico />
        <DadosEmplacafacil />
        <Effects />
      </ModalNovoPedido>
    </Store.Provider>
  )
}

function ModalNovoPedido({ children, cancelar, ok }) {
  const { modalVisible } = ListaPedidosStore.useState()
  const dispatch = ListaPedidosStore.useDispatch()

  function closeModal() {
    dispatch({ type: 'closeModal' })
  }

  return (
    <Modal
      visible={modalVisible === 'NOVO_PEDIDO'}
      title="Novo pedido"
      onCancel={closeModal}
      footer={[cancelar, ok]}
    >
      {children}
    </Modal>
  )
}

function Ok() {
  const dispatch = ListaPedidosStore.useDispatch()
  const dispatchStore = Store.useDispatch()
  const { loading } = Store.useState()
  const { idEmpresaAtiva } = AuthStore.useState()
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)

  const disabled = loading.getServicos

  return (
    <Button
      size="large"
      type="primary"
      key="ok"
      disabled={disabled}
      onClick={() =>
        dispatchStore(
          Store.thunks.createEmplacamento(dispatch, idEmpresaAtiva, empresaAtiva?.municipio?.uf)
        )
      }
      loading={loading.createEmplacamento}
    >
      Ok
    </Button>
  )
}

function Cancelar() {
  const dispatch = ListaPedidosStore.useDispatch()

  function closeModal() {
    dispatch({ type: 'closeModal' })
  }

  return (
    <Button size="large" onClick={closeModal} key="close">
      Cancelar
    </Button>
  )
}

function CodigoAutorizacao() {
  const { codigoAutorizacao } = Store.useState()
  const dispatch = Store.useDispatch()
  const listaPedidosDispatch = ListaPedidosStore.useDispatch()
  const { idEmpresaAtiva } = AuthStore.useState()
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)

  return (
    <label>
      Código de Autorização {empresaAtiva?.municipio?.uf === 'DF' ? 'ou Placa' : ''}
      <Input
        id="codigoAutorizacao"
        autoFocus
        size="large"
        value={codigoAutorizacao}
        onChange={(e) => dispatch(Store.actions.changeCodigoAutorizacao(e.target.value))}
        placeholder={`Informe o código de autorização${
          empresaAtiva?.municipio?.uf === 'DF' ? ' ou Placa' : ''
        }`}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            dispatch(
              Store.thunks.createEmplacamento(
                listaPedidosDispatch,
                idEmpresaAtiva,
                empresaAtiva?.municipio?.uf
              )
            )
          }
        }}
      />
    </label>
  )
}

function InfoPreenchimentoCodigoAutorizacaoObrigatorio() {
  const { Layout } = InfoPreenchimentoCodigoAutorizacaoObrigatorio
  const { codigoAutorizacao } = Store.useState()
  if (codigoAutorizacao.length === 24) return null
  return <Layout>Informe o código de autorização do DETRAN com 24 caractéres.</Layout>
}
InfoPreenchimentoCodigoAutorizacaoObrigatorio.Layout = styled.div`
  font-style: italic;
  margin-top: 24px;
`

function Servico() {
  const { servico, loading } = Store.useState()
  const { Layout } = Servico
  if (loading.getServicos) return <Layout>Carregando ... </Layout>
  if (!servico?.id) return null
  return (
    <Layout>
      <div>Serviço</div>
      <strong>{servico.descricao}</strong>
    </Layout>
  )
}
Servico.Layout = styled.div`
  margin: 12px 0;
  padding: 12px;
  border: 1px solid #e3e3e3;
  strong {
    font-size: 1.2em;
  }
`

function Effects() {
  const { codigoAutorizacao, servico } = Store.useState()
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)
  const dispatch = Store.useDispatch()

  useEffect(() => {
    const expectedLength = empresaAtiva?.municipio?.uf === 'GO' ? 24 : 15
    if (!codigoAutorizacao) return
    if (codigoAutorizacao.length === expectedLength || isPlaca(codigoAutorizacao))
      dispatch(Store.thunks.getServico(codigoAutorizacao))
  }, [codigoAutorizacao])

  return null
}

function DadosEmplacafacil() {
  const { LabelCheckbox } = DadosEmplacafacil
  const { valorInformado, criaPedidoEmplacafacil, servico } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleChangeCriaPedidoEmplacafacil() {
    localStorage.setItem('cria-pedido-emplacafacil', !criaPedidoEmplacafacil ? 'true' : 'false')
    dispatch(Store.actions.changeCriaPedidoEmplacafacil(!criaPedidoEmplacafacil))
  }

  if (!servico) return null

  return (
    <>
      <label>
        Valor
        <IntlNumberInput
          value={valorInformado}
          onChange={(e) => dispatch(Store.actions.changeValorInformado(e.target.value))}
          placeholder="Informe o valor total do pedido"
          locale="pt-BR"
          prefix="R$ "
          precision={2}
          className="ant-input ant-input-lg"
        />
      </label>
      <LabelCheckbox>
        <Input
          type="checkbox"
          checked={criaPedidoEmplacafacil}
          onChange={handleChangeCriaPedidoEmplacafacil}
        />
        Criar pedido no emplacafácil
      </LabelCheckbox>
    </>
  )
}
DadosEmplacafacil.LabelCheckbox = styled.label`
  display: flex;
  align-items: center;
  input {
    width: auto;
    margin: 8px;
  }
`

Servico.tamanhoCorreto = (codigoAutorizacao: string | undefined) => {
  if (!codigoAutorizacao) return false
  if (codigoAutorizacao.replace(/^[0]+/g, '').length === 21) return true
  return false
}

function unmaskMoney(value) {
  return +value.replace(/R\$ /, '').replace('.', '').replace(',', '.')
}

const getServicoId = (
  tipoVeiculo,
  tiposPlacas: { dianteira?: any; traseira?: any; segundaTraseira?: any }
): SERVICOS => {
  if (['MOTOCICLETA', 'MOTONETA', 'CICLOMOTOR', 'TRICICLO'].includes(tipoVeiculo))
    return SERVICOS.PLACA_DE_MOTO
  if (tiposPlacas.dianteira && tiposPlacas.traseira) return SERVICOS.PAR_DE_PLACAS
  if (tiposPlacas.dianteira) return SERVICOS.PLACA_DIANTEIRA
  if (tiposPlacas.traseira) return SERVICOS.PLACA_TRASEIRA
  if (tiposPlacas.segundaTraseira) return SERVICOS.SEGUNDA_PLACA_TRASEIRA
  throw new Error('Serviço referente à autorização não encontrado')
}

function StoreShow({ children, when }: { when: (state) => boolean; children: ReactNode }) {
  const state = Store.useState()
  if (!when(state)) return null
  return <>{children}</>
}

function isPlaca(searchStr: string): boolean {
  return /^[A-Z]{3}[0-9][A-Z][0-9]{2}$/.test(searchStr.toUpperCase())
}
