import { DeleteOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { notification, Button, Modal } from 'antd'
import React from 'react'
import { AuthStore } from '../../auth/AuthStore'
import { DisplayFlex } from '../../common/DisplayFlex'
import { ProdutoModel, TIPOS_PRODUTOS } from '../../common/Models'
import { BlanksScanner } from '../../common/seplac'
import { RenderPlaca } from './Components/RenderPlaca'
import { ListaPedidosStore } from './ListaPedidosStore'

export function Substituicao() {
  const dispatch = ListaPedidosStore.useDispatch()
  const { modalVisible, pedidoAtivo, produtoAntigo, produtoNovo } = ListaPedidosStore.useState()
  const { idEmpresaAtiva } = AuthStore.useState()

  const { Layout, ModalSubstituicao } = Substituicao

  function closeModal() {
    dispatch(ListaPedidosStore.actions.fechaModalSubstituicao())
  }

  async function substituirEstampagem() {
    if (!produtoAntigo.destino) {
      return notification.error({ message: 'Selecione o destino do blank substituído' })
    }

    const itemPosition = pedidoAtivo?.produtos.findIndex(
      (item) => item.serial === produtoNovo.serial.toString()
    )
    let produtoNovoASubstituir

    if (pedidoAtivo?.servico.descricao === 'Par de Placas') {
      produtoNovoASubstituir = pedidoAtivo?.produtos?.[itemPosition]?.id
    }

    dispatch(
      ListaPedidosStore.thunks.substituirEstampagem(
        {
          pedidoId: pedidoAtivo?.id,
          produtoNovoId:
            pedidoAtivo?.servico.descricao === 'Par de Placas'
              ? produtoNovoASubstituir
              : pedidoAtivo?.produtos?.[itemPosition]?.id,
          produtoAntigoId: produtoAntigo?.id,
          produtoAntigoDestino: produtoAntigo.destino,
        },
        idEmpresaAtiva
      )
    )
  }

  function setDestino(value) {
    dispatch(ListaPedidosStore.actions.setDestinoProdutoAntigo(value))
  }

  const tipoProduto = produtoAntigo?.tipoProdutoId

  return (
    <ModalSubstituicao
      visible={modalVisible === 'SUBSTITUICAO'}
      title="Substituição de Blank"
      onCancel={closeModal}
      footer={[
        <Button size="large" onClick={closeModal} key="close">
          Cancelar
        </Button>,
        <Button onClick={substituirEstampagem} size="large" type="primary" key="ok">
          Substituir Estampagem
        </Button>,
      ]}
    >
      <div style={{ textAlign: 'center', maxWidth: 280, margin: 'auto' }}>
        <RenderPlaca pedido={pedidoAtivo} />
        <div>
          {' '}
          <strong>Cód. Detran:</strong> {pedidoAtivo?.emplacamento?.codigoAutorizacaoDetran}
        </div>
        <div>
          {' '}
          <strong>Cód. Denatran:</strong> {pedidoAtivo?.emplacamento?.codigoAutorizacaoDenatran}
        </div>
      </div>
      <Produto tipoProduto={tipoProduto} />

      <Layout>
        <hr
          style={{
            border: '0',
            borderTop: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
          }}
        />
        <span>
          Destino do blank a ser substituído - <strong>{produtoAntigo.serial}</strong>{' '}
        </span>
        <div style={{ marginTop: '10px' }}>
          <div style={{ marginBottom: '10px' }}>
            <label>
              <input
                style={{ marginRight: '4px' }}
                onChange={(e) => setDestino(e.target.value)}
                name="destinoBlank"
                type="radio"
                value="devolucao_fabricante"
              />
              Devolver ao Fabricante
            </label>
          </div>
          <div style={{ marginBottom: '10px', marginRight: '4px' }}>
            <label>
              <input
                style={{ marginRight: '4px' }}
                onChange={(e) => setDestino(e.target.value)}
                name="destinoBlank"
                type="radio"
                value="manter_estoque"
              />
              Manter ao Estoque
            </label>
          </div>
        </div>
      </Layout>
    </ModalSubstituicao>
  )
}

Substituicao.ModalSubstituicao = styled(Modal)`
  @media (max-width: 600px) {
    .ant-modal-footer {
      display: flex;
      justify-content: space-around;
    }

    button {
      width: 100%;
      margin: 0 5px 10px 5px !important;
      padding: 9px;
    }

    .ant-modal-close-x {
      margin-top: -12px;
      margin-left: 248px;
    }
  }
`

Substituicao.LabelCheckbox = styled.div`
  display: flex;
  align-items: center;
  input {
    width: auto;
    margin: 8px;
  }
`
Substituicao.Layout = styled.div`
  strong {
    font-size: 17px;
  }

  span {
    font-size: 17px;
  }

  @media (max-width: 600px) {
    .ant-modal-footer {
      display: flex;
      justify-content: space-around;
    }
  }
`

const labelTipoProdutoSubstituicao = {
  [TIPOS_PRODUTOS.PLACA_DIANTEIRA_CARRO]: (
    <>
      Novo Serial da placa <strong>DIANTEIRA</strong>
    </>
  ),
  [TIPOS_PRODUTOS.PLACA_TRASEIRA_CARRO]: (
    <>
      Novo Serial da placa <strong>TRASEIRA</strong>
    </>
  ),
  [TIPOS_PRODUTOS.PLACA_MOTO]: (
    <>
      Novo Serial da placa de <strong>MOTO</strong>
    </>
  ),
  [TIPOS_PRODUTOS.SEGUNDA_PLACA_TRASEIRA_CARRO]: (
    <>
      Novo Serial da <strong>SEGUNDA</strong> placa <strong>TRASEIRA</strong>
    </>
  ),
}

const Produto = ({ tipoProduto }: { tipoProduto: TIPOS_PRODUTOS }) => {
  const { Layout, Label, Serial } = Produto
  const { produtoNovo } = ListaPedidosStore.useState()
  const dispatch = ListaPedidosStore.useDispatch()

  function removeProduto() {
    dispatch(ListaPedidosStore.actions.limpaCampoNovoProduto())
  }

  function setSerial(produto: ProdutoModel) {
    dispatch(ListaPedidosStore.actions.setNovoProduto({ tipoProduto, produto }))
  }

  if (Object.keys(produtoNovo).length > 0) {
    return (
      <Layout>
        <Label>{labelTipoProdutoSubstituicao[tipoProduto]}</Label>
        <DisplayFlex style={{ alignItems: 'center' }}>
          <Serial>{produtoNovo.serial}</Serial>
          <Button icon={<DeleteOutlined />} onClick={removeProduto} />
        </DisplayFlex>
      </Layout>
    )
  }
  return (
    <Layout>
      <Label>{labelTipoProdutoSubstituicao[tipoProduto]}</Label>
      <BlanksScanner onScan={setSerial} showProduto={false} />
    </Layout>
  )
}
Produto.Layout = styled.div`
  margin-top: 16px;
`
Produto.Label = styled.div``
Produto.Serial = styled.div`
  font-size: 1.8em;
  font-weight: bold;
  margin-right: 8px;
`
