import { Button, Checkbox, Form, Input, Modal, Spin, Tabs, notification } from 'antd'
import { ListaPedidosStore } from './ListaPedidosStore'
import { Show } from '../../common/Show'
import { useFormik, FormikProvider, useField, useFormikContext } from 'formik'
import styled from '@emotion/styled'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { TextField, TextFieldInput } from '../../common/TextField'
import IntlNumberInput from 'react-intl-number-input'
import { createSimpleStore } from 'react-simple-reducer'
import { ClienteModel, PedidoModel } from '../../common/Models'
import axios, { Axios } from 'axios'
import * as Yup from 'yup'
import useAxios from 'axios-hooks'
import { AuthStore, getEmpresaAtiva } from '../../auth/AuthStore'

export function NotaFiscal() {
  const { Flex } = NotaFiscal
  const dispatch = ListaPedidosStore.useDispatch()
  const { modalVisible, pedidoAtivo } = ListaPedidosStore.useState()
  const emiteNotaFiscal = AuthStore.useSelector(getEmpresaAtiva)?.emiteNotaFiscal

  function closeModal() {
    dispatch(ListaPedidosStore.actions.closeModal())
  }

  const init = useCallback(
    (dispatch) => {
      dispatch(NotaFiscalStore.actions.init(pedidoAtivo))
      if (modalVisible && pedidoAtivo?.emplacamento?.id) {
        dispatch(NotaFiscalStore.thunks.getInvoice(pedidoAtivo.emplacamento.id, pedidoAtivo))
      }
    },
    [modalVisible]
  )

  if (!emiteNotaFiscal) {
    return <NotaFiscalSimplificada />
  }

  return (
    <NotaFiscalStore.Provider init={init}>
      <NotaFiscalStore.GetState>
        {({ loading, invoice }) => (
          <Formulario>
            <Modal
              visible={modalVisible === 'NOTA_FISCAL'}
              title="Nota fiscal"
              onCancel={closeModal}
              width={600}
              footer={<BotoesAcoes />}
            >
              <DynamicModalContent />
            </Modal>
          </Formulario>
        )}
      </NotaFiscalStore.GetState>
    </NotaFiscalStore.Provider>
  )
}
NotaFiscal.Flex = styled.div`
  display: flex;
  margin-top: 8px;
  gap: 8px;
  & > div {
    flex: 1;
  }
`

function DynamicModalContent() {
  const { pedidoAtivo } = ListaPedidosStore.useState()
  const { loading, invoice } = NotaFiscalStore.useState()

  if (loading) return <div>Carregando ... </div>
  if (invoice?.status === 'PROCESSING')
    return (
      <div style={{ padding: '48px 0', textAlign: 'center', fontSize: '1.4em' }}>
        Nota fiscal em processamento
      </div>
    )
  if (invoice?.status === 'DONE') return <NotaFiscalEmitida />
  if (invoice?.status === 'REJECTED') return <NotaFiscalRejeitada />
  if (invoice?.status === 'ERROR_SENDING') return <NotaFiscalErroAoEnviar />
  if (pedidoAtivo?.emplacamento.numeroNF) return <NumeroNFAntiga />
  return <NovaNotaFiscal />
}

function NumeroNFAntiga() {
  const { pedidoAtivo } = ListaPedidosStore.useState()
  return (
    <div>
      <div>Número da nota fiscal</div>
      <div style={{ fontWeight: 'bold', fontSize: '1.3em' }}>
        {pedidoAtivo?.emplacamento.numeroNF}
      </div>
    </div>
  )
}

function NotaFiscalRejeitada() {
  const { invoice } = NotaFiscalStore.useState()
  const dispatch = NotaFiscalStore.useDispatch()

  function handleReenviar() {
    dispatch(NotaFiscalStore.actions.reenviarNotaFiscal())
  }

  return (
    <div>
      <h3>Nota fiscal Rejeitada.</h3>
      <div>Motivo:</div>
      <div>{invoice?.message}</div>
      <Button
        type="primary"
        size="large"
        onClick={handleReenviar}
        style={{ margin: '24px auto 0 auto', display: 'block' }}
      >
        Reenviar
      </Button>
    </div>
  )
}

function NotaFiscalErroAoEnviar() {
  const dispatch = NotaFiscalStore.useDispatch()

  function handleReenviar() {
    dispatch(NotaFiscalStore.actions.reenviarNotaFiscal())
  }

  return (
    <div>
      <h3>Erro ao enviar a nota fiscal!</h3>
      <div>Por favor, informe o suporte. Você também pode tentar novamente.</div>
      <Button
        type="primary"
        size="large"
        onClick={handleReenviar}
        style={{ margin: '24px auto 0 auto', display: 'block' }}
      >
        Reenviar
      </Button>
    </div>
  )
}

function NotaFiscalEmitida() {
  const { invoice } = NotaFiscalStore.useState()
  const [src, setSrc] = useState('about:blank')

  useEffect(() => {
    downloadPdf()
  }, [invoice])

  async function downloadPdf() {
    if (!invoice) return
    const response = await axios.get(`/emplacamentos/nota-fiscal/pdf/${invoice.issuanceId}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    })
    const blob = new Blob([response.data], { type: 'application/pdf' })
    const objectUrl = URL.createObjectURL(blob)
    setSrc(objectUrl)
  }

  const handleDownloadPdf = () => {
    const link = document.createElement('a')
    link.href = src
    link.download = `nota-fiscal-${invoice?.issuanceId || 'download'}.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div>
      <div>
        <div>Número da nota fiscal</div>
        <div style={{ fontWeight: 'bold', fontSize: '1.3em' }}>{invoice?.number}</div>
      </div>
      <iframe src={src} frameBorder={0} style={{ width: '100%', height: 500, marginTop: 24 }} />
    </div>
  )
}

function NovaNotaFiscal() {
  const { informaDestinatario } = NotaFiscalStore.useState()
  const [{ value: cpfCnpj }] = useField('cpfCnpj')
  const { Flex } = NotaFiscal
  return (
    <Form>
      <ValorCobranca />
      <ValorNotaFiscal />
      <ConfirmacaoValorDivergente />
      <TabsContainer>
        <Tabs.TabPane tab="NFe" key="nfe">
          <MesmaTitularidade />
          <Flex>
            <CpfCnpj />
            <Nome />
          </Flex>
          <Flex>
            <Cep />
            <Endereco />
          </Flex>
          <Flex>
            <Municipio />
            <Bairro />
          </Flex>
          <Flex>
            <Numero />
            <Complemento />
          </Flex>
          <Flex>
            <Email />
            <Telefone />
          </Flex>
          <Show condition={cpfCnpj?.length > 14}>
            <Flex>
              <InscricalEstadual />
            </Flex>
          </Show>
          <Flex>
            <InformacoesAdicionais />
          </Flex>
        </Tabs.TabPane>
        <Tabs.TabPane tab="NFCe" key="nfce">
          <InformarDestinatario />
          <Show condition={informaDestinatario}>
            <MesmaTitularidade />
            <Flex>
              <CpfCnpj />
              <Nome />
            </Flex>
          </Show>
        </Tabs.TabPane>
      </TabsContainer>
    </Form>
  )
}

function TabsContainer({ children }: { children: ReactNode }) {
  const { tipo } = NotaFiscalStore.useState()
  const dispatch = NotaFiscalStore.useDispatch()
  function handleChangeTab(tab) {
    dispatch(NotaFiscalStore.actions.changeTab(tab))
  }

  return <Tabs onChange={handleChangeTab} activeKey={tipo} children={children} />
}

function Formulario({ children }: { children: ReactNode }) {
  const { pedidoAtivo } = ListaPedidosStore.useState()
  const { tipo, informaDestinatario, mesmaTitularidade, invoice } = NotaFiscalStore.useState()
  const validationSchema = useMemo(
    () => getValidationSchema(tipo, informaDestinatario, mesmaTitularidade),
    [tipo, informaDestinatario, mesmaTitularidade]
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      valorNotaFiscal: invoice?.value ?? pedidoAtivo?.emplacamento?.valorNotaFiscal,
      cpfCnpj: invoice?.cpfCnpj ?? pedidoAtivo?.emplacamento?.cliente.cpfCnpj,
      nome: invoice?.name ?? pedidoAtivo?.emplacamento?.cliente.nome,
      email: invoice?.email ?? pedidoAtivo?.emplacamento?.cliente.email,
      telefone: invoice?.phone ?? pedidoAtivo?.emplacamento?.cliente.telefone,
      cep: invoice?.postalCode,
      endereco: invoice?.street,
      municipio: invoice?.city,
      bairro: invoice?.neighborhood,
      numero: invoice?.addressNumber,
      complemento: invoice?.complement,
      codigoCidadeIbge: invoice?.cityCode,
      uf: invoice?.state,
      inscricaoEstadual: invoice?.stateRegistration,
      informacoesAdicionais: invoice?.additionalInformation,
    },
    validationSchema,
    onSubmit(values) {
      console.log(values)
    },
  })

  return <FormikProvider value={formik}>{children}</FormikProvider>
}

function StoreShow({
  when,
  children,
}: {
  when: (state: ReturnType<typeof NotaFiscalStore.useState>) => boolean
  children: any
}) {
  const state = NotaFiscalStore.useState()
  if (when(state)) return children
  return null
}

function BotoesAcoes() {
  const { Container } = BotoesAcoes
  const {
    confirmacaoValorDivergente,
    valor,
    mesmaTitularidade,
    tipo,
    loadingEmissaoNotaFiscal,
    informaDestinatario,
    loading,
    invoice,
  } = NotaFiscalStore.useState()
  const { pedidoAtivo } = ListaPedidosStore.useState()
  const listaPedidosDispatch = ListaPedidosStore.useDispatch()
  const notaFiscalDispatch = NotaFiscalStore.useDispatch()
  const formik = useFormikContext<any>()

  async function createNotaFiscal(action: 'SAVE' | 'SEND') {
    if (action === 'SEND') {
      if (
        unmask(valor) !== pedidoAtivo?.emplacamento.valorNotaFiscal &&
        !confirmacaoValorDivergente
      ) {
        return notification.error({
          message: 'É necessário confirmar que o valor da nota fiscal é divergente',
        })
      }
    }

    if (!unmask(valor)) {
      return notification.error({ message: 'O valor da nota fiscal é obrigatório' })
    }

    Object.keys(formik.values).forEach((fieldName) => formik.setFieldTouched(fieldName, true))

    try {
      const validationSchema = getValidationSchema(tipo, informaDestinatario, mesmaTitularidade)
      await validationSchema.validate(formik.values)
    } catch (e: any) {
      return notification.error({ message: 'Existem campos inválidos no formulário' })
    }

    notaFiscalDispatch(NotaFiscalStore.actions.emissaoStarted())
    try {
      const {
        email,
        telefone,
        cep,
        endereco,
        municipio,
        bairro,
        numero,
        complemento,
        codigoCidadeIbge,
        inscricaoEstadual,
        informacoesAdicionais,
        uf,
      } = formik.values
      const cpfCnpj = mesmaTitularidade
        ? pedidoAtivo.emplacamento.cliente.cpfCnpj
        : formik.values.cpfCnpj
      const nome = mesmaTitularidade ? pedidoAtivo.emplacamento.cliente.nome : formik.values.nome

      const requestData: Record<string, any> = {
        emplacamentoId: pedidoAtivo.emplacamento.id,
        valorNotaFiscal: unmask(valor),
        tipoNotaFiscal: tipo,
        acao: action,
      }

      if (tipo === 'nfe' || informaDestinatario) {
        requestData.cpfCnpj = cpfCnpj
        requestData.nome = nome
      }

      if (tipo === 'nfe') {
        requestData.cep = cep
        requestData.endereco = endereco
        requestData.municipio = municipio
        requestData.bairro = bairro
        requestData.numero = numero
        requestData.complemento = complemento
        requestData.email = email
        requestData.telefone = telefone
        requestData.codigoCidadeIbge = codigoCidadeIbge
        requestData.uf = uf
        requestData.inscricaoEstadual = inscricaoEstadual
      }

      if (informacoesAdicionais) requestData.informacoesAdicionais = informacoesAdicionais

      const responseCreateNotaFiscal = await axios
        .put(`emplacamentos/nota-fiscal`, requestData)
        .then((response) => response?.data)

      const verb = action === 'SAVE' ? 'salva' : 'emitida'
      notification.success({ message: `Nota fiscal ${verb} com sucesso!` })
      notaFiscalDispatch(NotaFiscalStore.actions.emissaoSuccess(responseCreateNotaFiscal?.invoice))
    } catch (error: any) {
      const verb = action === 'SAVE' ? 'salvar' : 'emitir'
      const message = error?.response?.data?.message ?? `Erro ao ${verb} nota fiscal`
      notification.error({ message })
      notaFiscalDispatch(NotaFiscalStore.actions.emissaoError())
    }
  }

  if (loading) return null
  if (invoice && invoice.status !== 'WAITING') return null
  if (pedidoAtivo?.emplacamento?.numeroNF) return null

  return (
    <Container>
      <Button
        size="large"
        onClick={() => createNotaFiscal('SAVE')}
        loading={loadingEmissaoNotaFiscal}
      >
        Salvar
      </Button>
      <div className="flex-grow" />
      <Button
        size="large"
        onClick={() => listaPedidosDispatch(ListaPedidosStore.actions.closeModal())}
        key="close"
      >
        Cancelar
      </Button>
      <Button
        onClick={() => createNotaFiscal('SEND')}
        size="large"
        key="ok"
        loading={loadingEmissaoNotaFiscal}
        type="primary"
      >
        Emitir
      </Button>
    </Container>
  )
}
BotoesAcoes.Container = styled.div`
  .flex-grow {
    flex: 1;
  }
  display: flex;
`

function Email() {
  return <TextField name="email" label="E-mail" />
}

function CpfCnpj() {
  const { pedidoAtivo } = ListaPedidosStore.useState()
  const { mesmaTitularidade } = NotaFiscalStore.useState()

  const [{ value: cpfCnpj }] = useField('cpfCnpj')
  const formik = useFormikContext<{
    nome
    email
    telefone
    cep
    endereco
    municipio
    bairro
    numero
    complemento
    codigoCidadeIbge
    uf
    inscricaoEstadual
  }>()

  useEffect(() => {
    const cpfCnpjUnformatted = cpfCnpj?.replace(/\D/g, '') ?? ''
    if (cpfCnpjUnformatted.length === 14) getCompanyInfo(cpfCnpjUnformatted)
  }, [cpfCnpj])

  async function getCompanyInfo(cnpj: string) {
    try {
      const companyInfo: {
        cep: string
        email: null
        numero: string
        complemento: string
        nome_fantasia: string
        ddd_telefone_1: string
      } = await axios.get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`).then((x) => x.data)
      if (formik.values.nome === pedidoAtivo?.emplacamento?.cliente.nome || !formik.values.nome) {
        formik.setFieldValue('nome', companyInfo.nome_fantasia)
      }
      if (!formik.values.cep) formik.setFieldValue('cep', companyInfo.cep)
      if (!formik.values.telefone) formik.setFieldValue('telefone', companyInfo.ddd_telefone_1)
      if (!formik.values.complemento) formik.setFieldValue('complemento', companyInfo.complemento)
      if (!formik.values.numero) formik.setFieldValue('numero', companyInfo.numero)
      if (!formik.values.email) formik.setFieldValue('email', companyInfo.email)
    } catch (error) {}
  }

  if (mesmaTitularidade) {
    const value = pedidoAtivo?.emplacamento.cliente.cpfCnpj
    return <TextFieldInput label="CPF/CNPJ" value={value} disabled={true} />
  }
  return <TextField name="cpfCnpj" disabled={mesmaTitularidade} label="CPF/CNPJ" mask="cpfCnpj" />
}

function Nome() {
  const { mesmaTitularidade } = NotaFiscalStore.useState()
  const { pedidoAtivo } = ListaPedidosStore.useState()
  if (mesmaTitularidade) {
    const value = pedidoAtivo?.emplacamento.cliente.nome
    return (
      <div style={{ flex: 2 }}>
        <TextFieldInput label="Nome" value={value} disabled={true} />
      </div>
    )
  }
  return <TextField name="nome" style={{ flex: 2 }} label="Nome" />
}

function Cep() {
  const [{ value: cep }] = useField('cep')
  const [{ value: cpfCnpj }] = useField('cpfCnpj')
  const dispatch = NotaFiscalStore.useDispatch()
  const formik = useFormikContext<{}>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const unformattedCep = cep?.replace(/\D/g, '') ?? ''
    handleChange(unformattedCep)
  }, [cep])

  async function handleChange(cep) {
    if (cep.length !== 8) {
      formik.setValues({
        ...formik.values,
        endereco: '',
        bairro: '',
        municipio: '',
        uf: '',
        codigoCidadeIbge: '',
      })
      dispatch(NotaFiscalStore.actions.markCepHasStreet())
      return
    }
    setLoading(true)
    try {
      const response = await getInfoFromCep(cep, cpfCnpj)
      if (response.logradouro) {
        formik.setValues({
          ...formik.values,
          municipio: response.localidade,
          endereco: response.logradouro,
          bairro: response.bairro,
          uf: response.uf,
          codigoCidadeIbge: response.ibge,
        })
        dispatch(NotaFiscalStore.actions.markCepHasStreet())
      } else {
        formik.setValues({
          ...formik.values,
          municipio: response.localidade,
          uf: response.uf,
          codigoCidadeIbge: response.ibge,
        })
        dispatch(NotaFiscalStore.actions.markCepDoesNotHaveStreet())
      }
    } catch (error) {
      notification.error({ message: 'Ocorreu um erro ao buscar os dados do endereço' })
    } finally {
      setLoading(false)
    }
  }

  return <TextField name="cep" mask="99.999-999" loading={loading} label="CEP" />
}

async function getInfoFromCep(cep: string, cpfCnpj: string) {
  if (cpfCnpj?.replace(/\D/g, '') === '02692394000545') {
    return {
      cep: '75710-010',
      logradouro: 'Rodovia BR 050 KM 281',
      complemento: '',
      unidade: '',
      bairro: 'Castelo Branco',
      localidade: 'Catalão',
      uf: 'GO',
      ibge: '5205109',
      gia: '',
      ddd: '64',
      siafi: '9301',
    }
  }
  return await fetch(`https://viacep.com.br/ws/${cep}/json`).then((x) => x.json())
}

function Endereco() {
  const { cepHasStreet } = NotaFiscalStore.useState()

  return <TextField name="endereco" disabled={cepHasStreet} label="Endereço" style={{ flex: 2 }} />
}

function Municipio() {
  return <TextField name="municipio" disabled label="Município" />
}

function Bairro() {
  const { cepHasStreet } = NotaFiscalStore.useState()
  return <TextField name="bairro" disabled={cepHasStreet} label="Bairro" />
}

function Numero() {
  return <TextField name="numero" label="Número" />
}

function Complemento() {
  return <TextField name="complemento" label="Complemento" />
}

function Telefone() {
  return <TextField name="telefone" mask="telefone" label="Telefone" />
}

function InscricalEstadual() {
  return <TextField name="inscricaoEstadual" label="Inscrição Estadual" />
}

function InformacoesAdicionais() {
  return <TextField name="informacoesAdicionais" label="Informações Adicionais" />
}

function unmask(valor) {
  if (!valor) return valor
  return +valor.replace?.('R$ ', '').replace('.', '').replace(',', '.')
}

function ValorCobranca() {
  const { pedidoAtivo } = ListaPedidosStore.useState()
  return (
    <div>
      <label>
        Valor informado ao DETRAN da venda
        <IntlNumberInput
          id="intlNumberInput"
          value={pedidoAtivo?.emplacamento?.valorNotaFiscal}
          locale="pt-BR"
          precision={2}
          className="ant-input ant-input-lg"
          disabled
        />
      </label>
    </div>
  )
}

function ValorNotaFiscal() {
  const dispatch = NotaFiscalStore.useDispatch()
  const { valor } = NotaFiscalStore.useState()
  return (
    <label>
      Valor da Nota Fiscal
      <IntlNumberInput
        id="intlNumberInputValor"
        value={valor}
        onChange={(e) => dispatch(NotaFiscalStore.actions.changeValor(e.target.value))}
        locale="pt-BR"
        precision={2}
        className="ant-input ant-input-lg"
      />
    </label>
  )
}

function InformarDestinatario() {
  const { informaDestinatario } = NotaFiscalStore.useState()
  const dispatch = NotaFiscalStore.useDispatch()

  function handleChange(e) {
    dispatch(NotaFiscalStore.actions.toggleInformaDestinatario())
  }

  return (
    <div>
      <label>
        <Checkbox checked={informaDestinatario} onChange={handleChange} /> Informar destinatário
      </label>
    </div>
  )
}

function MesmaTitularidade() {
  const { mesmaTitularidade } = NotaFiscalStore.useState()
  const dispatch = NotaFiscalStore.useDispatch()

  function handleChange(e) {
    dispatch(NotaFiscalStore.actions.toggleMesmaTitularidade())
  }

  return (
    <div>
      <label>
        <Checkbox checked={mesmaTitularidade} onChange={handleChange} /> Mesma titularidade
      </label>
    </div>
  )
}

function ConfirmacaoValorDivergente() {
  const dispatch = NotaFiscalStore.useDispatch()
  const { confirmacaoValorDivergente, valor } = NotaFiscalStore.useState()
  const { pedidoAtivo } = ListaPedidosStore.useState()

  if (pedidoAtivo?.emplacamento?.valorNotaFiscal === unmask(valor) && valor) return null
  return (
    <div style={{ marginTop: 12 }}>
      <label>
        <Checkbox
          checked={confirmacaoValorDivergente}
          onChange={(e) => dispatch(NotaFiscalStore.actions.toggleConfirmacaoValorDivergente())}
        />
        Confirmo que o valor da nota fiscal é divergente do valor informado ao DETRAN da venda
      </label>
    </div>
  )
}

function transformCurrency(value: number) {
  return new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}

function getValidationSchema(
  tipo: 'nfe' | 'nfce',
  informaDestinatario: boolean,
  mesmaTitularidade: boolean
) {
  if (tipo === 'nfe') {
    return Yup.object().shape({
      cpfCnpj: Yup.string().required('CPF/CNPJ é obrigatório'),
      nome: Yup.string().required('Nome é obrigatório'),
      email: Yup.string().email('Email inválido').required('Email é obrigatório'),
      telefone: Yup.string().required('Telefone é obrigatório'),
      cep: Yup.string().required('CEP é obrigatório'),
      endereco: Yup.string().required('Endereço é obrigatório'),
      bairro: Yup.string().required('Bairro é obrigatório'),
      numero: Yup.string().required('Número é obrigatório'),
      complemento: Yup.string().nullable(),
    })
  }

  if (informaDestinatario && !mesmaTitularidade) {
    return Yup.object().shape({
      cpfCnpj: Yup.string().required('CPF/CNPJ é obrigatório'),
      nome: Yup.string().required('Nome é obrigatório'),
    })
  }

  return Yup.object().shape({})
}

interface IInvoice {
  id: number
  createdAt: string
  updatedAt: string
  deletedAt: string
  status: string
  issuanceId: number
  externalId: string
  number: string
  message: string

  type: string
  value: number
  itemDescription: string
  cpfCnpj: string
  name: string
  email: string
  phone: string
  neighborhood: string
  postalCode: string
  cityCode: string
  city: string
  state: string
  street: string
  complement: string
  addressNumber: string
  streetType: string
  stateRegistration: string
  additionalInformation: string
}

const defaultValues = {
  cliente: null as ClienteModel | null,
  valor: null as string | null,
  enderecoDesabilitado: true,
  tipo: 'nfe' as 'nfe' | 'nfce',
  mesmaTitularidade: true,
  informaDestinatario: true,
  confirmacaoValorDivergente: false,
  loadingEmissaoNotaFiscal: false,
  loading: false,
  invoice: null as IInvoice | null,
  cepHasStreet: true,
}

const NotaFiscalStore = createSimpleStore(
  defaultValues,
  {
    init(state, pedido: PedidoModel) {
      const { cliente, valorNotaFiscal } = pedido?.emplacamento ?? {}
      return {
        ...defaultValues,
        valor: transformCurrency(valorNotaFiscal ? valorNotaFiscal : 0),
        cliente: cliente || null,
      }
    },
    toggleInformaDestinatario(state) {
      state.informaDestinatario = !state.informaDestinatario
    },
    toggleMesmaTitularidade(state) {
      state.mesmaTitularidade = !state.mesmaTitularidade
    },
    changeValor(state, valor) {
      state.valor = valor
    },
    toggleConfirmacaoValorDivergente(state) {
      state.confirmacaoValorDivergente = !state.confirmacaoValorDivergente
    },
    emissaoStarted(state) {
      state.loadingEmissaoNotaFiscal = true
    },
    emissaoError(state) {
      state.loadingEmissaoNotaFiscal = false
    },
    emissaoSuccess(state, invoice) {
      state.loadingEmissaoNotaFiscal = false
      state.invoice = invoice
    },
    changeTab(state, tab: 'nfce' | 'nfe') {
      state.tipo = tab
    },
    getInvoiceStarted(state) {
      state.loading = true
    },
    getInvoiceSuccess(
      state,
      payload: { invoice: IInvoice | null; tipo: string; mesmaTitularidade: boolean; valor: number }
    ) {
      state.valor = transformCurrency(payload.valor)
      state.invoice = payload.invoice
      state.tipo = payload.tipo as 'nfe' | 'nfce'
      state.mesmaTitularidade = payload.mesmaTitularidade
      state.loading = false
    },
    getInvoiceError(state) {
      state.loading = false
    },
    reenviarNotaFiscal(state) {
      if (state.invoice) {
        state.invoice.status = 'WAITING'
      }
    },
    markCepHasStreet(state) {
      state.cepHasStreet = true
    },
    markCepDoesNotHaveStreet(state) {
      state.cepHasStreet = false
    },
  },
  {
    thunks: {
      getInvoice(emplacamentoId: number, pedidoAtivo: PedidoModel) {
        return async (dispatch) => {
          try {
            dispatch(NotaFiscalStore.actions.getInvoiceStarted())
            const url = `emplacamentos/nota-fiscal/${emplacamentoId}`
            const response = await axios.get(url).then((x) => x?.data)
            const invoice: IInvoice | null = response?.invoices?.[0]
            const cpfCnpjTitular = pedidoAtivo?.emplacamento?.cliente?.cpfCnpj
            const mesmaTitularidade =
              !cpfCnpjTitular || !invoice || cpfCnpjTitular == invoice.cpfCnpj
            const tipo = invoice?.type || 'nfe'
            const valor = invoice?.value || pedidoAtivo.valor
            dispatch(
              NotaFiscalStore.actions.getInvoiceSuccess({ invoice, tipo, mesmaTitularidade, valor })
            )

            if (invoice?.number && !pedidoAtivo.emplacamento.numeroNF) {
              void axios
                .post('/emplacamentos/nota-fiscal/webhook', invoice)
                .catch((e) => console.log(e))
            }
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro'
            notification.error({ message })
            dispatch(NotaFiscalStore.actions.getInvoiceError())
          }
        }
      },
    },
  }
)

function NotaFiscalSimplificada() {
  const { modalVisible, pedidoAtivo } = ListaPedidosStore.useState()
  const dispatch = ListaPedidosStore.useDispatch()
  const [notaFiscal, setNotaFiscal] = useState('')

  const [{ loading }, req] = useAxios(
    {
      url: '/emplacamentos/nota-fiscal',
      method: 'PUT',
      data: { emplacamentoId: pedidoAtivo?.emplacamento?.id, numeroNotaFiscal: notaFiscal },
    },
    {
      manual: true,
    }
  )

  function closeModal() {
    dispatch(ListaPedidosStore.actions.closeModal())
  }
  async function handleClickSalvar() {
    if (!pedidoAtivo) return new Error('Pedido inexistente')

    try {
      await req()

      notification.success({ message: 'Nota fiscal salva com sucesso' })
      setNotaFiscal('')

      const novoPedido: PedidoModel = {
        ...pedidoAtivo,
        emplacamento: {
          ...pedidoAtivo.emplacamento,
          numeroNF: notaFiscal,
        },
      }

      dispatch(ListaPedidosStore.actions.setNotaFiscal({ notaFiscal, persist: true }))
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao salvar a nota fiscal'
      notification.error({ message })
    }
  }

  return (
    <Modal
      visible={modalVisible === 'NOTA_FISCAL'}
      style={{ top: 20 }}
      onCancel={closeModal}
      width={645}
      footer={[
        <Button key="close" onClick={closeModal}>
          Fechar
        </Button>,
        <Button key="ok" type="primary" onClick={handleClickSalvar}>
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading} tip="Salvando nota fiscal...">
        <div>
          <label>
            Nota Fiscal
            <Input
              placeholder="Informe a nota fiscal"
              value={notaFiscal}
              onChange={(e) => setNotaFiscal(e.target.value)}
            />
          </label>
        </div>
      </Spin>
    </Modal>
  )
}
