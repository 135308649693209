import { Badge, Modal, Table, Tabs, notification, Pagination, DatePicker, Dropdown } from 'antd'
import { format, parseISO } from 'date-fns'
import AnimateHeight from 'react-animate-height'
import Button from 'antd/es/button'
import React, { useEffect, useState } from 'react'
import useAxios from 'axios-hooks'

import { PedidoModel, PERMISSOES } from 'common/Models'
import { TextFieldInput } from 'common/TextField'
import styled from '@emotion/styled'

import { DisplayFlex } from '../../common/DisplayFlex'
import { Estampar } from './Estampar'
import { OrdemServico } from './OrdemServico'
import { Show } from '../../common/Show'
import { PAGE_SIZE, ListaPedidosStore } from './ListaPedidosStore'
import usePermissaoUsuario from '../../common/hooks/usePermissaoUsuario'

import {
  LinkOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  RedoOutlined,
  CreditCardOutlined,
  CloseSquareOutlined,
} from '@ant-design/icons'
import { TablePaginationConfig } from 'antd/lib/table'
import { Historico } from './Historico'
import { permissoesEmplacamento } from '../../Menu'
import { AuthStore, getEmpresaAtiva, getPermissoesEmpresa } from '../../auth/AuthStore'
import { RenderPlaca } from './Components/RenderPlaca'

import { RestrictedResource } from '../../common/RestrictedResource'
import confirm from 'antd/lib/modal/confirm'
import moment from 'moment'
import axios from 'axios'
import { ImagensInstalacao } from './ImagensInstalacao'
import useWindowDimensions from 'common/getWindowDimensions'
import { NovoPedido } from './NovoPedido'
import { Substituicao } from './Substituicao'
import { StatusEmplacamentoDetran } from '../../common/seplac/StatusEmplacamentoDetran'
import { EnvioEstampagem } from './EnvioEstampagem'
import { encodeHashCobranca } from '@hyperdev-tech/hash-cobranca'
import { NotaFiscal } from './NotaFiscal'

export const STATUS_TEXTOS = {
  AGUARDANDO_PAGAMENTO: 'Pedido Aberto',
  AGUARDANDO_ESTAMPAGEM: 'Aguardando Estampagem',
  ESTAMPADA: 'Estampada, Aguardando Instalação',
  INSTALADA: 'Instalada',
}

export const ListaPedidos = () => {
  const { Modals, ControlContainer } = ListaPedidos

  usePermissaoUsuario(permissoesEmplacamento, { changeRoute: true })

  return (
    <ListaPedidosStore.Provider>
      <ControlContainer>
        <BotaoNovoPedido />
        <BuscaPedido />
        <div style={{ flex: 1 }} />
        <Filtros />
      </ControlContainer>

      <ListaPedidosFiltro />

      <TabPedidos />

      <Modals>
        <OrdemServico />
        <Estampar />
        <NovoPedido />
        <Historico />
        <Boleto />
        <ImagensInstalacao />
        <Substituicao />
        <EnvioEstampagem />
        <NotaFiscal />
      </Modals>
      <Init />
    </ListaPedidosStore.Provider>
  )
}
ListaPedidos.Modals = styled.div``

ListaPedidos.ControlContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`

function BotaoNovoPedido() {
  const { Layout } = BotaoNovoPedido
  const dispatch = ListaPedidosStore.useDispatch()
  const hasPermissao = usePermissaoUsuario([PERMISSOES.CRIAR_PEDIDO_ESTAMPAGEM])
  function handleClick() {
    if (!hasPermissao) {
      return notification.warn({ message: 'Você não tem permissão para criar novos pedidos' })
    }
    dispatch(ListaPedidosStore.actions.openModalNovoPedido())
  }
  return (
    <Layout>
      <Button size="large" onClick={handleClick}>
        <PlusOutlined />
        Novo Pedido
      </Button>
    </Layout>
  )
}
BotaoNovoPedido.Layout = styled.div`
  @media (max-width: 600px) {
    margin: 8px 0;
    width: 100%;

    button {
      width: 100%;
    }
  }
`

function Init() {
  const dispatch = ListaPedidosStore.useDispatch()
  const state = ListaPedidosStore.useState()
  const { query, abaAtual } = state
  const { idEmpresaAtiva } = AuthStore.useState()
  const permissoes = AuthStore.useSelector(getPermissoesEmpresa)
  useEffect(() => {
    setTimeout(() => {
      dispatch(ListaPedidosStore.thunks.getPedidos(idEmpresaAtiva))
    })
  }, [abaAtual, query, idEmpresaAtiva])

  useEffect(() => {
    dispatch(ListaPedidosStore.actions.setPermissoesUsuarioEmpresa(permissoes))
  }, [permissoes])

  return null
}

function TabPedidos() {
  const state = ListaPedidosStore.useState()

  const dispatch = ListaPedidosStore.useDispatch()

  const totalAguardandoPagamento =
    state.countByStatus?.find((p) => p.status === 'AGUARDANDO_PAGAMENTO')?.count ?? 0
  const totalAguardandoEstampagem =
    state.countByStatus?.find((p) => p.status === 'AGUARDANDO_ESTAMPAGEM')?.count ?? 0
  const totalEstampada = state.countByStatus?.find((p) => p.status === 'ESTAMPADA')?.count ?? 0

  return (
    <Tabs
      defaultActiveKey="AGUARDANDO_ESTAMPAGEM"
      onChange={(e: any) => dispatch({ type: 'setAbaAtual', payload: e })}
    >
      <Tabs.TabPane
        key="AGUARDANDO_PAGAMENTO"
        tab={<LabelBadge total={totalAguardandoPagamento} label="Pedidos" />}
      >
        <Lista>{(pedido) => <AcoesPedidos pedido={pedido} />}</Lista>
      </Tabs.TabPane>

      <Tabs.TabPane
        key="AGUARDANDO_ESTAMPAGEM"
        tab={<LabelBadge total={totalAguardandoEstampagem} label="Aguardando Estampagem" />}
      >
        <Lista>{(pedido) => <AcoesAguardandoEstampagem pedido={pedido} />}</Lista>
      </Tabs.TabPane>

      <Tabs.TabPane key="ESTAMPADA" tab={<LabelBadge total={totalEstampada} label="Estampadas" />}>
        <Lista>{(pedido) => <AcoesEstampadas pedido={pedido} />}</Lista>
      </Tabs.TabPane>

      <Tabs.TabPane key="INSTALADA" tab="Instaladas">
        <Lista>{(pedido) => <AcoesInstaladas pedido={pedido} />}</Lista>
      </Tabs.TabPane>
    </Tabs>
  )
}
TabPedidos.ButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;

  button {
    width: 120px;
    height: 54px;
    white-space: normal;
  }

  .pendente-msg {
    display: none;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    button {
      width: 100%;
      height: default;
      white-space: normal;
    }

    .pendente-msg {
      display: block;
    }
  }
`

const LabelBadge = ({ total, label }) => {
  return (
    <Badge count={total}>
      <div style={{ margin: '0 12px' }}>{label}</div>
    </Badge>
  )
}

const AcoesPedidos = ({ pedido }: { pedido: PedidoModel }) => {
  const { ButtonsGroup } = TabPedidos
  const dispatch = ListaPedidosStore.useDispatch()
  const { idEmpresaAtiva } = AuthStore.useState()

  function handleClickOrdemServico(pedido: PedidoModel) {
    dispatch(ListaPedidosStore.actions.setPedidoOs(pedido))
  }

  async function handleClickEnviarEstampagem() {
    dispatch(ListaPedidosStore.thunks.enviarEstampagem(pedido))
  }

  function handleClickExcluir(emplacamentoId: number) {
    confirm({
      title: 'Tem certeza que deseja apagar a estampagem?',
      onOk: () =>
        dispatch(ListaPedidosStore.thunks.excluirEstampagem(idEmpresaAtiva, emplacamentoId)),
    })
  }

  return (
    <>
      <ButtonsGroup>
        <Button onClick={() => handleClickOrdemServico(pedido)}>Ordem de Serviço</Button>
        <Button onClick={async () => await handleClickEnviarEstampagem()}>
          Enviar para Estampagem
        </Button>
        <RestrictedResource
          permissoes={[PERMISSOES.APAGAR_EMPLACAMENTO]}
          renderWhenForbidden={null}
        >
          <Button onClick={() => handleClickExcluir(pedido.emplacamento.id)}>Excluir</Button>
        </RestrictedResource>
      </ButtonsGroup>
      <StatusPagamento pedido={pedido} />
    </>
  )
}

function StatusPagamento({ pedido }: { pedido: PedidoModel }) {
  const { PedidoPago, Layout } = StatusPagamento

  const linkCobranca = `https://rep.hyperpay.com.br/cobranca/${encodeHashCobranca(
    +pedido.emplacamento.cobrancaId
  )}`

  if (pedido.emplacamento.dataPagamento) {
    return (
      <Layout>
        <PedidoPago>
          Pedido Pago em:{' '}
          {format(new Date(pedido.emplacamento.dataPagamento), `dd/MM/yyyy 'às' HH:mm`)}
        </PedidoPago>
      </Layout>
    )
  }

  if (!pedido.emplacamento.cobrancaId)
    return (
      <Layout>
        <GerarCobranca pedido={pedido} />
      </Layout>
    )

  return (
    <Layout>
      <div>
        <a href={linkCobranca} target="_blank">
          Abrir link de cobrança <LinkOutlined />
        </a>
      </div>
      {/* <CobrancaPdv pedido={pedido} /> */}
      <ChecarStatusPagamento pedido={pedido} />
    </Layout>
  )
}
StatusPagamento.PedidoPago = styled.div`
  font-style: italic;
`
StatusPagamento.Layout = styled.div`
  margin-top: 12px;
  text-align: center;
  a: hover {
    text-decoration: underline;
  }
`

function CobrancaPdv({ pedido }: { pedido: PedidoModel }) {
  const cobrancaId = pedido.emplacamento.cobrancaId
  const dispatch = ListaPedidosStore.useDispatch()
  const [cancelar, setCancelar] = useState(false)

  async function handlePdv(meioPagamento: 'CARTAO_CREDITO' | 'CARTAO_DEBITO') {
    setCancelar(true)
    dispatch(
      ListaPedidosStore.thunks.cobrancaPdv({
        pdvId: undefined,
        cobrancaId,
        meioPagamento,
      })
    )
  }

  async function handleCancelar() {
    setCancelar(false)
    dispatch(
      ListaPedidosStore.thunks.cancelarPdv({
        pdvId: undefined,
        cobrancaId,
      })
    )
  }

  return null

  return (
    <div style={{ display: 'none', flexDirection: 'column' }}>
      {cancelar ? (
        <a onClick={() => handleCancelar()}>
          Cancelar Intenção de Pagamento <CloseSquareOutlined />
        </a>
      ) : (
        <>
          <a onClick={() => handlePdv('CARTAO_DEBITO')}>
            Débito <CreditCardOutlined />
          </a>
          <a onClick={() => handlePdv('CARTAO_CREDITO')}>
            Crédito <CreditCardOutlined />
          </a>
        </>
      )}
    </div>
  )
}

function ChecarStatusPagamento({ pedido }: { pedido: PedidoModel }) {
  const codigoAutorizacaoDetran = pedido.emplacamento.codigoAutorizacaoDetran
  const dispatch = ListaPedidosStore.useDispatch()

  const [{ loading }, req] = useAxios(
    `/emplacamentos/boleto-emplacafacil/${codigoAutorizacaoDetran}`,
    { manual: true }
  )

  async function handleClick(e) {
    e.preventDefault()
    try {
      const cobranca = await req().then((x) => x.data)
      if (cobranca.dataPgto) {
        dispatch(
          ListaPedidosStore.actions.updateDadosCobrancaEmplacamento({
            pedidoId: pedido.id,
            dataPagamento: cobranca.dataPgto,
          })
        )
      }

      if (!cobranca.dataPgto) {
        notification.error({ message: 'Estampagem não foi paga!' })
      }
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro ao checar'
      notification.error({ message })
    }
  }

  if (loading) return <div>Checando... </div>
  return (
    <div>
      <a onClick={handleClick}>Checar status do pagamento</a>
    </div>
  )
}

function GerarCobranca({ pedido }: { pedido: PedidoModel }) {
  const codigoAutorizacao = pedido.emplacamento.codigoAutorizacaoDetran

  const dispatch = ListaPedidosStore.useDispatch()

  const [{ loading }, req] = useAxios(
    {
      url: '/emplacamentos/boleto-emplacafacil',
      data: {
        codigoAutorizacao,
        valorTotalEstampagem: pedido.emplacamento.valorTotalEstampagem,
      },
      method: 'post',
    },
    { manual: true }
  )

  async function handleClick(e) {
    e.preventDefault()
    try {
      const cobranca = await req().then((x) => x.data)
      dispatch(
        ListaPedidosStore.actions.updateDadosCobrancaEmplacamento({
          pedidoId: pedido.id,
          cobrancaId: cobranca.id,
        })
      )
    } catch (error: any) {
      const message = error.response?.data?.message
      if (message) notification.error({ message })
    }
  }

  if (loading) {
    return <div>Gerando cobrança ... </div>
  }
  return (
    <div>
      <a onClick={handleClick}>
        Gerar cobrança <PlusCircleOutlined />
      </a>
    </div>
  )
}

const AcoesAguardandoEstampagem = ({ pedido }: { pedido: PedidoModel }) => {
  const { ButtonsGroup } = TabPedidos
  const dispatch = ListaPedidosStore.useDispatch()
  const { idEmpresaAtiva } = AuthStore.useState()
  const emiteNotaFiscal = AuthStore.useSelector(getEmpresaAtiva)?.emiteNotaFiscal

  function handleClickOrdemServico(pedido: PedidoModel) {
    dispatch(ListaPedidosStore.actions.setPedidoOs(pedido))
  }

  function handleClickEstampar(pedido) {
    dispatch(ListaPedidosStore.thunks.iniciarEstampagem(pedido))
  }

  function handleClickNotaFiscal(pedido) {
    dispatch(ListaPedidosStore.actions.openModalNotaFiscal(pedido))
  }

  function handleClickExcluir(emplacamentoId: number) {
    confirm({
      title: 'Tem certeza que deseja apagar a estampagem?',
      onOk: () =>
        dispatch(ListaPedidosStore.thunks.excluirEstampagem(idEmpresaAtiva, emplacamentoId)),
    })
  }

  function handleClickReservar(pedido: PedidoModel) {}

  return (
    <>
      <ButtonsGroup>
        <Button onClick={() => handleClickOrdemServico(pedido)}>Ordem de Serviço</Button>
        <Button onClick={() => handleClickEstampar(pedido)}>Estampar</Button>
        <Show condition={emiteNotaFiscal}>
          <Button onClick={() => handleClickNotaFiscal(pedido)}>Nota Fiscal</Button>
        </Show>
        {/* <Show condition={!pedido.emplacamento.codigoAutorizacaoDenatran}>
          <Button onClick={() => handleClickReservar(pedido)}>Reservar</Button>
        </Show> */}
        <RestrictedResource
          permissoes={[PERMISSOES.APAGAR_EMPLACAMENTO]}
          renderWhenForbidden={null}
        >
          <Button onClick={() => handleClickExcluir(pedido.emplacamento.id)}>Excluir</Button>
        </RestrictedResource>
      </ButtonsGroup>
      <StatusPagamento pedido={pedido} />
    </>
  )
}
const AcoesEstampadas = ({ pedido }: { pedido: PedidoModel }) => {
  const { ButtonsGroup } = TabPedidos
  const dispatch = ListaPedidosStore.useDispatch()
  const { idEmpresaAtiva } = AuthStore.useState()
  const emiteNotaFiscal = AuthStore.useSelector(getEmpresaAtiva)?.emiteNotaFiscal

  const { auditoriaVencida } = ListaPedidosStore.useState()

  function handleClickOrdemServico(pedido: PedidoModel) {
    dispatch(ListaPedidosStore.actions.setPedidoOs(pedido))
  }

  function handleClickInstalar(pedido: PedidoModel) {
    dispatch(ListaPedidosStore.thunks.encerrarEstampagem(pedido))
  }

  function handleCancelaEstampagem(pedido: PedidoModel) {
    confirm({
      title: 'Tem certeza que deseja cancelar a estampagem?',
      onOk: () => dispatch(ListaPedidosStore.thunks.cancelarEstampagem(idEmpresaAtiva, pedido.id)),
    })
  }

  function handleClickNotaFiscal(pedido: PedidoModel) {
    dispatch(ListaPedidosStore.actions.openModalNotaFiscal(pedido))
  }

  return (
    <>
      <ButtonsGroup>
        <Button onClick={() => handleClickOrdemServico(pedido)}>Ordem de Serviço</Button>
        {/* <EmitirBoletoEmplacafacil pedido={pedido} /> */}
        <Button
          title={auditoriaVencida ? 'Auditoria Vencida' : ''}
          disabled={auditoriaVencida}
          onClick={() => handleClickInstalar(pedido)}
        >
          Instalar
        </Button>
        <Show condition={emiteNotaFiscal}>
          <Button onClick={() => handleClickNotaFiscal(pedido)}>Nota Fiscal</Button>
        </Show>
        <RestrictedResource
          permissoes={[PERMISSOES.CANCELAR_INICIO_ESTAMPAGEM]}
          renderWhenForbidden={null}
        >
          <Button onClick={() => handleCancelaEstampagem(pedido)}>Cancelar Estampagem</Button>
        </RestrictedResource>
      </ButtonsGroup>
      <StatusPagamento pedido={pedido} />
    </>
  )
}
const AcoesInstaladas = ({ pedido }: { pedido: PedidoModel }) => {
  const { Button } = AcoesInstaladas
  const dispatch = ListaPedidosStore.useDispatch()
  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)
  const emiteNotaFiscal = empresaAtiva?.emiteNotaFiscal
  const ufEmpresaAtiva = empresaAtiva?.municipio?.uf

  function handleClickOrdemServico(pedido: PedidoModel) {
    dispatch(ListaPedidosStore.actions.setPedidoOs(pedido))
  }

  function handleClickFotosInstalacao(pedido: PedidoModel) {
    dispatch(ListaPedidosStore.actions.openModalImagensInstalacao(pedido))
  }

  function handleUpdatePedido(pedido: PedidoModel) {
    console.log({ pedido })
    // dispatch(ListaPedidosStore.actions.pedido)
  }

  function handleOpenModelNotaFiscal() {
    dispatch(ListaPedidosStore.actions.openModalNotaFiscal(pedido))
  }

  return (
    <DisplayFlex spaceBetween={12}>
      <div style={{ display: 'flex', gap: 4, flexDirection: 'column' }}>
        <Button onClick={() => handleClickOrdemServico(pedido)}>Ordem de Serviço</Button>
        <Button onClick={() => handleClickFotosInstalacao(pedido)}>Imagens da Instalação</Button>
        <Show condition={emiteNotaFiscal}>
          <Button onClick={handleOpenModelNotaFiscal}>Nota Fiscal</Button>
        </Show>
      </div>

      <Show condition={ufEmpresaAtiva === 'GO'}>
        <StatusEmplacamentoDetran
          pedido={pedido}
          onUpdate={handleUpdatePedido}
          onOpenModalNotaFiscal={handleOpenModelNotaFiscal}
        />
      </Show>
    </DisplayFlex>
  )
}
AcoesInstaladas.Button = styled(Button)`
  width: 175px;
  flex: 1;
`

function EmitirBoletoEmplacafacil({ pedido }: { pedido: PedidoModel }) {
  const dispatch = ListaPedidosStore.useDispatch()
  const [loading, setLoading] = React.useState(false)

  async function handleClickEmitirBoletoEmplacafacil(pedido: PedidoModel) {
    try {
      setLoading(true)
      const responseCriaPedidoEmplacafacil = await axios
        .post('/emplacamentos/boleto-emplacafacil', {
          codigoAutorizacao: pedido.emplacamento.codigoAutorizacaoDetran,
          valorTotalEstampagem: pedido.emplacamento.valorTotalEstampagem,
        })
        .then((x) => x.data)
      if (!responseCriaPedidoEmplacafacil)
        return notification.error({ message: 'Ocorreu um erro ao emitir o boleto' })
      dispatch(ListaPedidosStore.actions.displayBoleto(responseCriaPedidoEmplacafacil))
    } catch (error: any) {
      const message =
        error.response?.data?.message ?? 'Ocorreu um erro ao emitir o boleto no Emplacafácil'
      notification.error({ message })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      onClick={async () => await handleClickEmitirBoletoEmplacafacil(pedido)}
      loading={loading}
    >
      Emitir Boleto no Emplacafácil
    </Button>
  )
}

function Lista({ children }: { children? }) {
  const { Card, LayoutInfo, ButtonPanel } = Lista

  const empresaAtiva = AuthStore.useSelector(getEmpresaAtiva)

  const { pedidos, carregandoLista } = ListaPedidosStore.useState()

  const { width } = useWindowDimensions()

  const columns = [
    { title: 'Placa', render: (pedido) => _renderPlaca(pedido, empresaAtiva?.municipio.uf) },
    { title: 'Data', render: _renderData },
    { title: 'Origem', render: _renderOrigem },
    { title: 'Serviços', render: _renderServicos },
    // { title: 'Valor', render: _renderValor },
  ]
  if (children) columns.push({ title: '', render: (pedido) => <>{children(pedido)}</> })

  return (
    <>
      <Show condition={width > 600}>
        <Table
          size="small"
          bordered
          dataSource={pedidos}
          columns={columns}
          pagination={false}
          loading={carregandoLista}
          locale={{ emptyText: 'Nenhum resultado' }}
          rowKey={(record: any) => record.id}
        />
        <Paginacao />
      </Show>

      <Show condition={width <= 600}>
        <>
          {pedidos.map((pedido) => {
            return (
              <Card key={pedido.id}>
                <LayoutInfo style={{ display: 'grid', justifyContent: 'center' }}>
                  <strong style={{ textAlign: 'center' }}>Placa:</strong>
                  {_renderPlaca(pedido, empresaAtiva?.municipio.uf)}
                </LayoutInfo>

                <LayoutInfo style={{ display: 'flex' }}>
                  <strong>Data: &nbsp;</strong>
                  <span>{_renderData(pedido)}</span>
                </LayoutInfo>

                <LayoutInfo>
                  <strong>Origem:</strong>
                  {_renderOrigem(pedido)}
                </LayoutInfo>

                <LayoutInfo>
                  <strong>Serviços:</strong>
                  <span>{_renderServicos(pedido)}</span>
                </LayoutInfo>
                <Show condition={children}>
                  <ButtonPanel>
                    <>{children(pedido)}</>
                  </ButtonPanel>
                </Show>
              </Card>
            )
          })}
        </>
      </Show>
    </>
  )
}

Lista.Card = styled.div`
  background: #f2f2f2;
  margin: 18px 0;
  padding: 2px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 4px #b3b3b3;
`

Lista.LayoutInfo = styled.div`
  margin: 10px;
  font-size: 15px;
  span {
    word-break: break-all;
  }
`

Lista.ButtonPanel = styled.div`
  display: grid;
  padding: 18px;
  button {
    margin-bottom: -8px;
  }
`

function Paginacao() {
  const { Layout } = Paginacao
  const [paginaAtual, setPaginaAtual] = useState(1)
  const { totalPedidos, abaAtual: status } = ListaPedidosStore.useState()
  const dispatch = ListaPedidosStore.useDispatch()

  useEffect(() => {
    const payload = { offset: paginaAtual * PAGE_SIZE - PAGE_SIZE, limit: PAGE_SIZE }
    dispatch({ type: 'setQuery', payload })
  }, [paginaAtual])

  const paginacaoConfig: TablePaginationConfig = {
    total: totalPedidos,
    pageSize: PAGE_SIZE,
    current: paginaAtual,
    onChange: setPaginaAtual,
  }

  if (status !== 'INSTALADA') return null
  return (
    <Layout>
      <Pagination {...paginacaoConfig} />
    </Layout>
  )
}
Paginacao.Layout = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`

function Boleto() {
  const { modalVisible, boleto } = ListaPedidosStore.useState()
  const dispatch = ListaPedidosStore.useDispatch()

  function closeModal() {
    dispatch(ListaPedidosStore.actions.closeModal())
  }

  function handleClick() {
    closeModal()
    const linkCobranca = `https://rep.hyperpay.com.br/cobranca/${encodeHashCobranca(boleto.id)}`
    window.open(linkCobranca)
  }

  return (
    <Modal
      visible={modalVisible === 'BOLETO'}
      title="Boleto"
      onCancel={closeModal}
      maskClosable={false}
      footer={[
        <Button size="large" onClick={closeModal} key="close">
          Fechar
        </Button>,
      ]}
    >
      <Show condition={boleto}>
        <h2>Boleto gerado com sucesso!</h2>

        <Button
          onClick={handleClick}
          type="primary"
          size="large"
          style={{ width: '100%', marginTop: 8 }}
        >
          Clique aqui para abrir o boleto
        </Button>
      </Show>
      <Show condition={!boleto}>
        <h2>O pedido foi criado com sucesso, porém o boleto não foi gerado!</h2>
        <p>
          Por favor{' '}
          <a href="https://emplacafacil.net" target="_blank" rel="noreferrer">
            acesse o Emplacafácil
          </a>{' '}
          para a emissão do boleto.
        </p>
      </Show>
    </Modal>
  )
}

// _eslint-disable-next-line_ @typescript-eslint/no-unused-vars
function TextoServicoFormatado({ textoServico }) {
  const newStr = {
    'Placa de Moto': (
      <>
        Placa de <strong>Moto</strong>
      </>
    ),
    'Par de Placas': (
      <>
        <strong>Par</strong> de Placas
      </>
    ),
    'Placa Dianteira': (
      <>
        Placa <strong>Dianteira</strong>
      </>
    ),
    'Placa Traseira': (
      <>
        Placa <strong>Traseira</strong>
      </>
    ),
    'Segunda Placa Traseira': (
      <>
        <strong>Segunda</strong> Placa <strong>Traseira</strong>
      </>
    ),
  }[textoServico]
  return newStr || null
}

function BuscaPedido() {
  const { Layout } = BuscaPedido
  const dispatch = ListaPedidosStore.useDispatch()
  const { idEmpresaAtiva } = AuthStore.useState()
  const [exibeBuscaAvancada, setExibeBuscaAvancada] = useState(false)
  const [placa, setPlaca] = useState('')
  const [cpf, setCpf] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [nome, setNome] = useState('')
  const [{ data, loading }, fetchLacracaoFiltro] = useAxios(
    {
      url: '/emplacamentos',
      params: { empresasIds: idEmpresaAtiva, placa, cpf, cnpj, nome },
    },
    {
      manual: true,
    }
  )

  useEffect(() => {
    if (data && !data?.rows.length) {
      return notification.error({
        message: 'Nenhum pedido encontrado com esses parâmetros de busca',
      })
    }
    dispatch(ListaPedidosStore.actions.consultaPedidosSuccess(data))
  }, [data])

  async function handleClickBuscar(e) {
    e.preventDefault()
    void fetchLacracaoFiltro()
  }

  function handleClickBuscaAvancada() {
    setExibeBuscaAvancada(!exibeBuscaAvancada)
  }

  return (
    <Layout>
      <form onSubmit={handleClickBuscar}>
        <Styles.BuscarPlacasContainer>
          <Show condition={!exibeBuscaAvancada}>
            <Styles.TextFieldInputUpperCase
              placeholder="Informe a Placa"
              value={placa}
              allowClear
              hasPlaca={!!placa}
              onChange={(e) => setPlaca(e.target.value)}
              mask="aaa9a99"
              autoFocus
            />
            <Button loading={loading} htmlType="submit" type="primary">
              Buscar
            </Button>
          </Show>
          <div style={{ flex: 1 }}></div>
          <Button onClick={handleClickBuscaAvancada}>
            {exibeBuscaAvancada ? 'Fechar busca avançada' : 'Busca avançada'}
          </Button>
        </Styles.BuscarPlacasContainer>
        <AnimateHeight height={exibeBuscaAvancada ? 'auto' : 0} duration={500}>
          <Styles.MaisFiltros>
            <div>
              <label htmlFor="">Busca por Placa</label>
              <Styles.TextFieldInputUpperCase
                placeholder="Informe a placa (Opcional)"
                size="large"
                value={placa}
                allowClear
                hasPlaca={!!placa}
                onChange={(e) => setPlaca(e.target.value)}
                mask="aaa9a99"
              />
            </div>
            <div>
              <label htmlFor="">CPF</label>
              <TextFieldInput
                placeholder="Informe o CPF do proprietário (Opcional)"
                size="large"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                mask="cpf"
              />
            </div>
            <div>
              <label htmlFor="">CNPJ</label>
              <TextFieldInput
                placeholder="Informe o CNPJ do proprietário (Opcional)"
                size="large"
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
                mask="cnpj"
              />
            </div>
            <div>
              <label htmlFor="">Nome</label>
              <TextFieldInput
                placeholder="Informe o Nome do proprietário (Opcional)"
                size="large"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </div>
            <div style={{ paddingTop: 12, textAlign: 'right' }}>
              <Button loading={loading} htmlType="submit" size="large" type="primary">
                Buscar
              </Button>
            </div>
          </Styles.MaisFiltros>
        </AnimateHeight>
      </form>
    </Layout>
  )
}
BuscaPedido.Layout = styled.div`
  @media (max-width: 600px) {
    margin: 7px 0;
  }
  input {
    font-size: 0.9rem;
  }
`

function Filtros() {
  const dispatch = ListaPedidosStore.useDispatch()
  const { idEmpresaAtiva } = AuthStore.useState()
  const {
    filtrosLista: { dataFim, dataInicio },
  } = ListaPedidosStore.useState()

  function setDatas(_, [dataInicio, dataFim]) {
    const parseData = (data, hora) => {
      if (!data) return data
      const strData = data.split('/').reverse().join('-')
      return new Date(`${strData} ${hora}`).toISOString()
    }
    dispatch(
      ListaPedidosStore.actions.setFiltros({
        dataInicio: parseData(dataInicio, '00:00:00'),
        dataFim: parseData(dataFim, '23:59:59'),
      })
    )
  }

  function handleClickFiltrar() {
    dispatch(ListaPedidosStore.thunks.getPedidos(idEmpresaAtiva))
  }

  function handleClickLimpar() {
    dispatch(ListaPedidosStore.actions.setFiltros({ dataFim: '', dataInicio: '' }))
    setTimeout(() => {
      dispatch(ListaPedidosStore.thunks.getPedidos(idEmpresaAtiva))
    })
  }

  const hasFiltro = dataInicio || dataFim

  return (
    <div>
      <Styles.BuscarPlacasContainer>
        <div>
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            onChange={setDatas}
            value={[dataInicio ? moment(dataInicio) : null, dataFim ? moment(dataFim) : null]}
            allowEmpty={[true, true]}
            style={{ width: '100%' }}
          />
        </div>
        <div>
          <Button type="primary" onClick={handleClickFiltrar}>
            Filtrar
          </Button>
        </div>
        <Show condition={hasFiltro}>
          <div>
            <Button onClick={handleClickLimpar}>Limpar filtros</Button>
          </div>
        </Show>
      </Styles.BuscarPlacasContainer>
    </div>
  )
}

function ListaPedidosFiltro() {
  const state = ListaPedidosStore.useState()
  const dispatch = ListaPedidosStore.useDispatch()
  const handleCancel = () => dispatch({ type: 'closeModal' })
  const handleClickPedido = (pedido) => dispatch({ type: 'setPedidoOs', payload: pedido })

  const listaPedidos = state.pedidosFiltro.map((pedido) => {
    return (
      <tr key={pedido.id} onClick={() => handleClickPedido(pedido)}>
        <td>{pedido.emplacamento.placa}</td>
        <td>
          {pedido.emplacamento.cliente.cpfCnpj} <br />
          {pedido.emplacamento.cliente.nome}
        </td>
        <td>{format(parseISO(pedido.createdAt), 'dd/MM/yyyy hh:mm')}</td>
        <td>{STATUS_TEXTOS[pedido.emplacamento.status]}</td>
        <td>{pedido.servico.descricao}</td>
      </tr>
    )
  })

  return (
    <Modal
      visible={state.modalVisible === 'SELECIONAR_ESTAMPAGEM'}
      onCancel={handleCancel}
      footer={[
        <Button onClick={handleCancel} key="1">
          Cancelar
        </Button>,
      ]}
      width={800}
    >
      <Styles.Table>
        <thead>
          <tr>
            <th>Placa</th>
            <th>Proprietário</th>
            <th>Data</th>
            <th>Status</th>
            <th>Serviço</th>
          </tr>
        </thead>
        <tbody>{listaPedidos}</tbody>
      </Styles.Table>
    </Modal>
  )
}

function _renderPlaca(pedido: PedidoModel, ufEmpresaAtiva: string | undefined) {
  return (
    <div style={{ textAlign: 'left', maxWidth: 200, lineHeight: 1.2 }}>
      <RenderPlaca pedido={pedido} />
      <Show condition={ufEmpresaAtiva === 'GO'}>
        <div>
          <strong style={{ fontSize: '.8em' }}>Cód. Denatran:</strong>
          <br /> {pedido.emplacamento.codigoAutorizacaoDenatran || '-'}
        </div>
      </Show>

      <div>
        <strong style={{ fontSize: '.8em' }}>Cód. Detran:</strong>
        <br /> {pedido.emplacamento.codigoAutorizacaoDetran || '-'}
      </div>
    </div>
  )
}

function _renderData(pedido: PedidoModel) {
  return (
    <div style={{ textAlign: 'center' }}>
      {format(parseISO(pedido.emplacamento.createdAt), 'dd/MM/yyyy HH:mm')}
    </div>
  )
}

function _renderOrigem(pedido: PedidoModel) {
  return (
    <div>
      {pedido.emplacamento.codDespachante ? (
        <RenderLayout>
          <div style={{ marginLeft: 6 }}>
            <div style={{ display: 'grid' }}>
              <span>
                <strong>CPF/CNPJ:</strong> {pedido.emplacamento.cliente.cpfCnpj}
              </span>
              <span>
                <strong>Cliente:</strong> {pedido.emplacamento.cliente.nome}
              </span>
            </div>
            <hr
              style={{
                border: '0',
                height: '0',
                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
              }}
            />
            <div style={{ display: 'grid' }}>
              <span>
                <strong>Despachante: </strong>
                {pedido.emplacamento.nomeDespachante}
              </span>
              <span>
                <strong>Cód. Despachante: </strong> {pedido.emplacamento.codDespachante}
              </span>
            </div>
          </div>
        </RenderLayout>
      ) : (
        <div>
          {pedido.emplacamento.cliente.cpfCnpj}-{pedido.emplacamento.cliente.nome}
        </div>
      )}
    </div>
  )
}

function _renderServicos(pedido: PedidoModel) {
  return (
    <RenderLayout>
      <div style={{ display: 'grid', marginLeft: 6 }}>
        <span>
          <strong>Descrição: </strong>
          {pedido.servico.descricao}
        </span>
        <span>
          <strong>Categoria: </strong> {pedido.emplacamento.categoriaVeiculo}
        </span>
      </div>
    </RenderLayout>
  )
}
const RenderLayout = styled.div`
  display: flex;
  align-items: center;
  strong {
    font-size: 0.9em;
  }
  @media (min-width: 600px) {
    strong {
      display: none;
    }
  }
`

const Styles = {
  Placa: styled('div')<{ fundo; texto; bordaMunicipio }>(
    (props) => `
    background: ${props.fundo};
    color: ${props.texto};
    padding: 2px 7px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    font-weight: bold;
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    line-height: 1.1;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 4px 8px;
    .municipio {
      font-size: 0.7em;
      font-weight: normal;
      border: 1px solid ${props.bordaMunicipio};
    }
  `
  ),
  Resumo: styled('div')`
    background: #f3f3f3;
    padding: 12px;
    margin: 24px;
    border-radius: 6px;
    text-align: right;
  `,
  ContainerServicos: styled('div')`
    display: flex;
    align-items: center;
    .ant-badge {
      position: relative;
    }
    .texto-badge {
      position: absolute;
      top: 7px;
      font-size: 0.7em;
      color: white;
      width: 40px;
      text-align: center;
    }
    .servicos-texto {
      margin-left: 16px;
    }
  `,
  Lacre: styled('div')`
    background: #795548;
    color: white;
    padding: 3px 6px;
    font-size: 0.8em;
    display: inline;
    border-radius: 4px;
  `,
  DisplayFlex: styled('div')``,
  BuscarPlacasContainer: styled('div')`
    flex: 1;
    display: flex;
    background: #f3f3f3;
    padding: 4px;
    border-radius: 4px;
    button {
      margin-left: 12px;
    }

    @media (max-width: 600px) {
      margin: 7px 0;
      display: grid;

      button {
        margin: 10px 0 2px 0;
        width: 100%;
      }
    }
  `,
  TextFieldInputUpperCase: styled(TextFieldInput)<{ hasPlaca }>`
    input {
      text-transform: ${(props) => (props.hasPlaca ? 'uppercase' : '')};
    }
  `,
  MaisFiltros: styled('div')`
    padding: 24px;
    margin: 0 8px 8px 8px;
    background: #f3f3f3;
  `,
  ItemListaPedidoFiltro: styled('tr')`
    &:nth-child(even) {
      background: #ececec;
    }
    td {
      padding: 6px;
    }
    &:hover {
      background: #dedede;
      cursor: pointer;
    }
  `,
  Table: styled('table')`
    border-collapse: collapse;
    width: 100%;
    table,
    th,
    td {
      border: 1px solid #444;
      padding: 8px;
    }
    tr:hover {
      background-color: #f3f3f3;
      cursor: pointer;
    }
    tr:nth-child(even) {
      background-color: #f7f7f7;
    }
  `,
}
